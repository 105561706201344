import { createSlice } from '@reduxjs/toolkit';

export const plansSlice = createSlice({
  name: 'plans',
  initialState: {
    showCheckout: false,
    defaultPlanId: '',
    defaultCoupon: null,
    landingType: 'fullPrice',
    plan: '',
    upsellPlan: '',
    couponDetails: {
      couponPrice: 0,
      couponName: '',
    },
    extraInfo: {},
    planDetails: null,
    offerShown: false,
  },
  reducers: {
    setDefaultPlanId: (state, action) => {
      state.defaultPlanId = action.payload;
    },
    setShowCheckout: (state, action) => {
      state.showCheckout = action.payload;
    },
    setLandingType: (state, action) => {
      state.landingType = action.payload || 'fullPrice';
    },
    setPlan: (state, action) => {
      state.plan = action.payload;
    },
    setUpsellPlan: (state, action) => {
      state.upsellPlan = action.payload;
    },
    setCouponDetails: (state, action) => {
      state.couponDetails = action.payload;
    },
    setExtraInfo: (state, action) => {
      state.extraInfo = action.payload;
    },
    setOfferShown: (state, action) => {
      state.offerShown = action.payload;
    },
    setDefaultCoupon: (state, action) => {
      state.defaultCoupon = action.payload;
    },
  },
});

export const {
  setPlan,
  setUpsellPlan,
  setLandingType,
  setShowCheckout,
  setDefaultPlanId,
  setDefaultCoupon,
  setCouponDetails,
  setExtraInfo,
  setOfferShown,
} = plansSlice.actions;

export const selectShowCheckout = (state) => state.plans.showCheckout;
export const selectDefaultPlanId = (state) => state.plans.defaultPlanId;
export const selectDefaultCoupon = (state) => state.plans.defaultCoupon;
export const selectPlan = (state) => state.plans.plan;
export const selectUpsellPlan = (state) => state.plans.upsellPlan;
export const selectLandingType = (state) => state.plans.landingType;
export const selectPlanCoupon = (state) => state.plans.couponDetails;
export const selectExtraInfo = (state) => state.plans.extraInfo;
export const selectOfferShown = (state) => state.plans.offerShown;

export default plansSlice.reducer;
