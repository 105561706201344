const theme = {
  colors: {
    colorWhite: '#ffffff',
    colorBlack: '#000000',
    colorText: '#323232',
    colorPrimary: '#00BFA5',
    colorActiveOptionBorder: '#00BFA5',
    colorActiveOptionBg: '#C2F0EA',
    colorInactiveOptionBg: '#EBFAF8',
    colorErrorText: '#FF5B5B',
    colorInputBorder: '#DEE0E6',
    colorInactiveProgressTrack: '#F3F3F3',
    colorInputPlaceholder: '#494E57',
    colorDomain: '#848484',
    colorDomainBorder: 'rgba(0, 0, 0, 0.1)',
    colorInactivePlanBorder: '#D4DAE0',
    colorInactiveCheckBorder: '#ADADAD',
    colorInactiveTrack: '#F2F2F2',
    colorCheckoutOr: '#707070',
    colorTimeBg: '#E9F4FF',
    colorReviewBg: '#F4F4F4',
    colorReviewLabelBg: '#e9e9e9',
    colorLabelText: '#5B5B5B',
    colorPaypalBg: '#f9c456',
    colorCheckoutInputBg: '#F9F9FA',
    colorCheckoutName: '#8696A6',
    gradient_bg: 'linear-gradient(67.83deg, #007F90 3.46%, #00BFA5 87.83%);',
    colorDisclaimerText: '#545454',
    colorWelcomeDisclaimerText: '#878787',
  },
  fontSizes: {
    font6: '6px',
    font8: '8px',
    font10: '10px',
    font12: '12px',
    font14: '14px',
    font16: '16px',
    font18: '18px',
    font20: '20px',
    font24: '24px',
    font28: '28px',
    font32: '32px',
  },
  fontWeights: {
    extraLight: 400,
    light: 500,
    medium: 600,
    bold: 700,
    extraBold: 800,
    black: 900,
  },
  lineHeights: {
    lineHeight7: '7px',
    lineHeight11: '11px',
    lineHeight12: '12px',
    lineHeight14: '14px',
    lineHeight16: '16px',
    lineHeight17: '17px',
    lineHeight18: '18px',
    lineHeight19: '19px',
    lineHeight20: '20px',
    lineHeight21: '21px',
    lineHeight22: '22px',
    lineHeight24: '24px',
    lineHeight28: '28px',
    lineHeight33: '33px',
    lineHeight38: '38px',
  },
};

export default theme;
