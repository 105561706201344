import React from 'react';
import styled, { css } from 'styled-components';

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 10px 0px 24px;
  position: relative;
  width: 100%;
  max-width: 343px;
  &::after {
    content: '';
    display: block;
    width: 100%;
    max-width: 343px;
    height: 1px;
    background: #d9ebf1;
    position: absolute;
    bottom: 0px;
    margin-bottom: 16px;
  }
  img {
    width: 19px;
    height: 24px;
  }

  @media screen and (max-width: 450px) {
    &::after {
      width: 100vw;
      max-width: none;
    }
  }
  ${({ $withBottomLine }) =>
    !$withBottomLine &&
    css`
      margin-bottom: 0px;
      padding-bottom: 0px;
      &::after {
        display: none;
      }
    `}
  ${({ $isOptions }) =>
    $isOptions &&
    css`
      position: absolute;
      top: 0;
    `}
`;

const GzText = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #23cca8;
`;

const AppHeaderLogo = ({ withBottomLine = true, isOptions }) => (
  <LogoWrapper $withBottomLine={withBottomLine} $isOptions={isOptions}>
    <img src="../assets/welcome/small-logo.png" alt="header logo" />
    <GzText>Geozilla</GzText>
  </LogoWrapper>
);

export default AppHeaderLogo;
