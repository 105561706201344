import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import Cookies from 'js-cookie';
import App from './App';
import { initAmplitude } from './analytics/amplitude';
import { initGismart } from './analytics/gismart';
import { initLockerStudio } from './analytics/lockerStudio';
import { initFirebase } from './analytics/firebase';
import store from './store/store';

const initCallback = (analytics) => {
  window.sessionStorage?.setItem(`${analytics}Init`, 'true');
};

initAmplitude(null, initCallback);

initFirebase();

const checkCookies = setInterval(() => {
  const id = Cookies.get('userUuid');
  if (id) {
    initGismart(id, initCallback);
    initLockerStudio(id, initCallback);
    clearInterval(checkCookies);
  }
}, 500);

const persistor = persistStore(store);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>
);
