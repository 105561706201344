import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import QuestionContainer from './QuestionContainer';

const Q1 = ({ isIntroOffer2 }) => {
  const { t } = useTranslation();

  const Q1Options = useMemo(
    () => [
      {
        id: 0,
        name: t('yes'),
        value: 'yes',
      },
      {
        id: 1,
        name: t('no'),
        value: 'no',
      },
      {
        id: 3,
        name: isIntroOffer2 ? t('not_sure_v2') : t('not_sure'),
        value: 'not_sure',
      },
    ],
    [t, isIntroOffer2]
  );

  return (
    <QuestionContainer
      title={t('question1')}
      options={Q1Options}
      nextUrl="q2"
      screenName="q1"
      centered={!isIntroOffer2}
      isIntroOffer2={isIntroOffer2}
      eventName={isIntroOffer2 && 'start_where_partner'}
    />
  );
};

export default Q1;
