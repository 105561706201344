import { createSlice } from '@reduxjs/toolkit';
import { setAmplitudeUserProperties } from '../analytics/amplitude';
import { setAnalyticsUserId } from '../analytics/common';
import { fetchWrapper } from '../utils';
import { setError } from './error';
import { setLoader } from './loader';
import { setEventData } from './events';
import Cookies from 'js-cookie';
import { v4 as uuidv4 } from 'uuid';

const { REACT_APP_API_URL1 } = process.env;
const { REACT_APP_API_URL2 } = process.env;
const { REACT_APP_STRIPE_PK } = process.env;
const { REACT_APP_STRIPE_NAME } = process.env;

export const signupSlice = createSlice({
  name: 'signup',
  initialState: {
    email: '',
    userName: '',
    isValid: false,
    token: null,
    gzUserID: null,
    isSuccess: null,
    userUuid: null,
    stripePublishKey: REACT_APP_STRIPE_PK,
    stripeAccountName: REACT_APP_STRIPE_NAME,
    stripePublishKey2: REACT_APP_STRIPE_PK,
    stripeAccountName2: REACT_APP_STRIPE_NAME,
    btToken: null,
    paymentSettingsStatus: null,
    skipPaywallDisabled: false,
    paymentProvider: null,
    userLocation: null,
  },
  reducers: {
    setEmail: (state, action) => {
      state.email = action.payload.email;
      state.isValid = action.payload.isValid;
    },
    setRequestResult: (state, action) => {
      const {
        access_token,
        entity: { geozilla_user_id, name, registration_source, email },
      } = action.payload;
      state.token = access_token;
      state.isSuccess = true;
      state.gzUserID = geozilla_user_id;
      state.userName = name === email ? '' : name;
      state.email = email;
      state.registrationSource = registration_source;
    },
    setUserToken: (state, action) => {
      state.token = action.payload;
    },
    setUserUuid: (state, action) => {
      state.userUuid = action.payload;
    },
    setBtToken: (state, action) => {
      state.btToken = action.payload;
    },
    setStripeData: (state, action) => {
      state.stripePublishKey = action.payload.stripePublishKey;
      state.stripeAccountName = action.payload.stripeAccountName;
    },
    setAlternativeStripeData: (state, action) => {
      state.stripePublishKey2 = action.payload['stripe_publishable_key'];
      state.stripeAccountName2 = action.payload['stripe_account_name'];
    },
    setAlternativeStripeDataAsMain: (state, action) => {
      state.stripePublishKey = state.stripePublishKey2;
      state.stripeAccountName = state.stripeAccountName2;
    },
    setPaymentSettingStatus: (state, action) => {
      state.paymentSettingsStatus = action.payload;
    },
    setSkipPaywallDisabled: (state, action) => {
      state.skipPaywallDisabled = action.payload;
    },
    setPaymentProvider: (state, action) => {
      state.paymentProvider = action.payload;
    },
    setUserLocation: (state, action) => {
      state.userLocation = action.payload;
    },
  },
});

export const {
  setEmail,
  setRequestResult,
  setUserToken,
  setUserUuid,
  setStripeData,
  setAlternativeStripeData,
  setAlternativeStripeDataAsMain,
  setBtToken,
  setPaymentSettingStatus,
  setSkipPaywallDisabled,
  setPaymentProvider,
  setUserLocation,
} = signupSlice.actions;

export const sendAPIRequest =
  ({ email, name, landingType, userUuid }) =>
  (dispatch) => {
    dispatch(setLoader(true));

    const params = {
      registration_source: 'email',
      name: name || email,
      email,
      landing_type: landingType,
      user_uuid: userUuid,
    };

    fetchWrapper(`${REACT_APP_API_URL1}/signup`, { body: params })
      .then((data) => {
        window.sessionStorage.setItem('access_token', data.access_token);
        dispatch(setRequestResult(data));
        dispatch(getUserLocation());
        dispatch(getPaymentSettings(data.access_token));
        setAnalyticsUserId(data.entity.geozilla_user_id);
      })
      .catch((error) => {
        dispatch(
          setError({
            show: true,
            text: error.errors?.info?.email || 'Failed to create an account',
            type: 'signup',
          })
        );
        dispatch(setLoader(false));
      });
  };

export const getPaymentSettings =
  (token, attemptNum = 0) =>
  (dispatch) => {
    let stripeParams = {
        stripePublishKey: REACT_APP_STRIPE_PK,
        stripeAccountName: REACT_APP_STRIPE_NAME,
      },
      status = null,
      paymentGateway = null;

    fetchWrapper(`${REACT_APP_API_URL2}/users/me/payments-settings`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((data) => {
        const {
          stripe_publishable_key,
          stripe_account_name,
          braintree_client_token,
          alternative_stripe_account,
          experiments,
          payment_gateway,
        } = data;

        paymentGateway =
          braintree_client_token && payment_gateway
            ? payment_gateway
            : 'stripe';
        dispatch(setBtToken(braintree_client_token || null));
        status = 'success';

        if (alternative_stripe_account) {
          dispatch(setAlternativeStripeData(alternative_stripe_account));
        }
        if (experiments?.force_3ds_for_eu_customers) {
          setAmplitudeUserProperties({
            '3Ds_test': experiments?.force_3ds_for_eu_customers || null,
          });
        }
        const skipPaywall = experiments?.discount_skip_paywall;
        if (skipPaywall) {
          setAmplitudeUserProperties({ skip_Paywall: skipPaywall || null });
          dispatch(setSkipPaywallDisabled(!!skipPaywall));
        }
        if (stripe_publishable_key) {
          stripeParams = {
            stripePublishKey: stripe_publishable_key,
            stripeAccountName: stripe_account_name,
          };
        }
      })
      .catch((error) => {
        console.error(error);
        if (attemptNum < 2) {
          setTimeout(
            () => dispatch(getPaymentSettings(token, ++attemptNum)),
            1000
          );
        } else {
          status = 'fail';
          paymentGateway = 'stripe';
        }
      })
      .then(() => {
        dispatch(setPaymentProvider(paymentGateway));
        dispatch(setStripeData(stripeParams));
        dispatch(setPaymentSettingStatus(status));
      })
      .then(() => dispatch(setLoader(false)));
  };

export const getUserLocation = () => (dispatch) => {
  fetchWrapper(`${REACT_APP_API_URL2}/cf-country`)
    .then((data) => {
      dispatch(setUserLocation(data?.country_code || null));
    })
    .catch((error) => {
      console.error(error);
    });
};

export const setUuid = (userUuid) => (dispatch) => {
  const cookieUuid = Cookies.get('userUuid');

  if (!userUuid) {
    if (cookieUuid) {
      userUuid = cookieUuid;
    } else {
      userUuid = uuidv4();
    }
  }

  if (!cookieUuid) Cookies.set('userUuid', userUuid, { expires: 365 });
  dispatch(setUserUuid(userUuid));
  dispatch(setEventData({ id: 'userUuid', data: cookieUuid }));
};

export const selectEmail = (state) => state.signup.email;
export const selectValidity = (state) => state.signup.isValid;
export const selectSuccess = (state) => state.signup.isSuccess;
export const selectToken = (state) => state.signup.token;
export const selectUserID = (state) => state.signup.gzUserID;
export const selectUserName = (state) => state.signup.userName;
export const selectUserUuid = (state) => state.signup.userUuid;
export const selectBtToken = (state) => state.signup.btToken;
export const selectStripePublishKey = (state) => state.signup.stripePublishKey;
export const selectStripeAccountName = (state) =>
  state.signup.stripeAccountName;
export const selectStripePublishKey2 = (state) =>
  state.signup.stripePublishKey2;
export const selectStripeAccountName2 = (state) =>
  state.signup.stripeAccountName2;
export const selectPaymentSettingsStatus = (state) =>
  state.signup.paymentSettingsStatus;
export const selectSkipPaywallDisabled = (state) =>
  state.signup.skipPaywallDisabled;
export const selectPaymentProvider = (state) => state.signup.paymentProvider;
export const selectUserLocation = (state) => state.signup.userLocation;

export default signupSlice.reducer;
