import styled, { css } from 'styled-components';

const Wrapper = styled.div`
  margin: 16px auto 0;
  width: 100%;
`;

const Stepper = styled.ol`
  --counter-size: 48px;
  list-style: none;
  padding: 0;
  margin: 0;
  position: relative;
  isolation: isolate;

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: calc(var(--counter-size) / 2);
    width: 2px;
    height: 100%;
    ${({ $inverted }) =>
      $inverted
        ? css`
            z-index: 1;
            background: repeating-linear-gradient(
              to bottom,
              rgba(255, 255, 255, 1),
              rgba(255, 255, 255, 1) 4px,
              transparent 4px,
              transparent 8px
            );
          `
        : css`
            background: repeating-linear-gradient(
              to bottom,
              #00ceb5,
              #00ceb5 4px,
              #ffffff 4px,
              #ffffff 8px
            );
          `}
    z-index: -1;
  }
`;

const StepperItem = styled.li`
  position: relative;
  display: flex;
  align-items: center;

  &:before {
    content: '${({ $count }) => $count + 1}';
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgb(0, 160, 139);
    flex: 0 0 var(--counter-size);
    height: var(--counter-size);
    border: 1px solid rgb(51, 204, 183);
    border-radius: 8px;
    background-color: rgb(229, 248, 246);
    margin-right: 16px;
    box-shadow: 0 0 8px 4px
      ${({ $inverted }) => ($inverted ? '#049999' : '#fff')};
  }

  ${({ $inverted }) =>
    !$inverted &&
    css`
      &:first-child {
        align-items: flex-start;
      }

      &:last-child {
        align-items: flex-end;
      }
    `};

  &:not(:last-child) {
    padding-bottom: 20px;
  }
`;

const StepperContent = styled.div``;

const StepperTitle = styled.h3`
  color: ${({ $inverted }) =>
    $inverted ? 'rgb(255,255,255)' : 'rgb(35,35,35)'};
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  text-align: left;
  margin: 0;
  word-break: break-word;
`;

const StepperDesc = styled.p`
  color: rgb(91, 91, 91);
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  text-align: left;
  margin-top: 4px;
`;

const Step = ({ index, title, description, inverted }) => (
  <StepperItem $count={index} $inverted={inverted}>
    <StepperContent>
      <StepperTitle $inverted={inverted}>{title}</StepperTitle>
      {description && <StepperDesc>{description}</StepperDesc>}
    </StepperContent>
  </StepperItem>
);

const AppVerticalStepper = ({ steps, inverted = false }) => (
  <Wrapper>
    <Stepper $inverted={inverted}>
      {steps.map((step, index) => (
        <Step
          key={index}
          index={index}
          title={step.title}
          description={step.description}
          inverted={inverted}
        />
      ))}
    </Stepper>
  </Wrapper>
);

export default AppVerticalStepper;
