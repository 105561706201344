import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import {
  selectEmail,
  selectSuccess,
  selectValidity,
  sendAPIRequest,
  selectUserUuid,
  selectUserName,
  selectPaymentSettingsStatus,
} from '../../store/signup';
import { selectLandingType } from '../../store/plans';
import { useStickyButtonStyles } from '../../hooks/useStickyButtonStyles';
import { EVENTS } from '../../analytics/events';
import {
  AppButton,
  AppDescription,
  AppHeaderLogo,
  AppHeadline,
} from '../../components';
import SignupInput from './SignupInput';
import { StarIcon } from '../../components/icons';
import { useSendEvents } from '../../hooks/useSendEvents';
import { selectEventsData } from '../../store/events';

const TrustTitle = styled.div`
  background-color: rgba(255, 244, 235, 1);
  border-radius: 4px;
  padding: 6px 8px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  span {
    font-size: 14px;
    font-weight: 700;
    color: rgba(242, 153, 74, 1);
    text-transform: uppercase;
    word-break: break-all;
  }
  svg {
    margin-right: 6px;
  }
`;

const IntroTitle = styled.h3`
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
  color: rgba(35, 35, 35, 1);
  white-space: pre-line;
`;

const IntroDescription = styled.p`
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: rgba(35, 35, 35, 1);
  margin: 8px 0 16px;
`;

const TextWrapper = styled.div`
  max-width: 327px;
  margin-bottom: 40px;
  @media screen and (max-width: 450px) {
    margin-bottom: 0;
  }
`;

const SignupScreen = () => {
  const { t } = useTranslation();
  const isValid = useSelector(selectValidity);
  const email = useSelector(selectEmail);
  const userUuid = useSelector(selectUserUuid);
  const name = useSelector(selectUserName);
  const landingType = useSelector(selectLandingType);
  const isSuccess = useSelector(selectSuccess);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [initEventSent, setInitEventStatus] = useState(false);
  const isIntroOffer2 = landingType === 'intro_offer2';
  const isFull2 = landingType === 'full2';
  const { stickyButtonStyles } = useStickyButtonStyles(
    16,
    isIntroOffer2 || isFull2 ? 110 : 56
  );
  const isIntroOffer = landingType === 'intro_offer';

  const settingsStatus = useSelector(selectPaymentSettingsStatus);
  const sendEvents = useSendEvents({ extraPathname: 'signup' });
  const eventsData = useSelector(selectEventsData);

  const onBtnClick = () => {
    window?.ttq?.track('Contact', {});
    sendEvents(EVENTS.onboardingEnterEmailSubmitted);
    sendEvents('signup__click', {
      button__continue: 'continue',
    });
    dispatch(sendAPIRequest({ email, name, landingType, userUuid }));
  };

  useEffect(() => {
    if (initEventSent) return;
    sendEvents(EVENTS.onboardingEnterEmailShown);
    sendEvents('signup__screen__load', {
      screen__load: '/signup',
    });
    setInitEventStatus(true);
  }, [initEventSent, sendEvents, eventsData]);

  useLayoutEffect(() => {
    if (isSuccess && settingsStatus) {
      window?.ttq?.track('CompleteRegistration', {});
      sendEvents(EVENTS.signupSuccess);
      sendEvents(EVENTS.LEAD);
      navigate(
        isIntroOffer || isIntroOffer2
          ? '/checkout'
          : isFull2
          ? '/plan-ready'
          : '/creating-profile'
      );
    }
  }, [
    isSuccess,
    navigate,
    sendEvents,
    isIntroOffer,
    settingsStatus,
    isIntroOffer2,
    eventsData,
    isFull2,
  ]);

  return (
    <>
      {(isIntroOffer || isIntroOffer2 || isFull2) && <AppHeaderLogo />}
      <TextWrapper>
        {isIntroOffer2 || isFull2 ? (
          <>
            <TrustTitle>
              <StarIcon />
              <span>{t('25_million_users_trust')}</span>
            </TrustTitle>
            <IntroTitle
              dangerouslySetInnerHTML={{ __html: t('profile_tailored') }}
            />
            <IntroDescription>{t('enter_email')}</IntroDescription>
          </>
        ) : (
          <>
            <AppHeadline customStyles={'margin: 0px 0px 8px'}>
              {t('whats_your_email')}
            </AppHeadline>
            <AppDescription customMargin={'0 0 32px 0'}>
              {t('email_description')}
            </AppDescription>
          </>
        )}
        <SignupInput isIntroOffer2={isIntroOffer2 || isFull2} />
      </TextWrapper>
      <AppButton
        onClick={onBtnClick}
        disabled={!isValid}
        stickyProps={stickyButtonStyles}
        bottomPosition={'16px'}
        customStyles={`${
          (isIntroOffer || isIntroOffer2 || isFull2) && 'min-height: 56px;'
        }`}
        withSecureLabels={isIntroOffer2 || isFull2}
      />
    </>
  );
};

export default SignupScreen;
