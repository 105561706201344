import { useEffect, useRef, useState, useLayoutEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  braintreePurchase,
  sendMainPurchaseRequest,
  selectFormValidity,
  selectID,
  selectShowPaymentError,
  selectSuccess,
  selectIfCardFormShown,
  setCardholderName,
  setIfCardFormShown,
  setShowPaymentError,
} from '../../store/checkout';
import {
  selectAnalyticsData,
  selectEventsData,
  setEventDataList,
} from '../../store/events';
import {
  selectBtToken,
  selectEmail,
  selectToken,
  selectUserUuid,
  selectPaymentProvider,
  selectStripeAccountName,
} from '../../store/signup';
import {
  selectDefaultPlanId,
  selectExtraInfo,
  selectPlan,
  selectPlanCoupon,
  selectShowCheckout,
  selectOfferShown,
  setPlan,
  setShowCheckout,
} from '../../store/plans';
import { selectLandingType } from '../../store/plans';
import { setCheckoutLoader } from '../../store/loader';
import { EVENTS } from '../../analytics/events';
import { PLANS } from '../../analytics/plans';
import {
  sendPurchaseEvent,
  sendRunwayerConversion,
} from '../../analytics/common';
import { setAmplitudeUserProperties } from '../../analytics/amplitude';
import { AppButton } from '../../components';
import styled, { css } from 'styled-components';
import braintree from 'braintree-web';
import { Elements, PaymentRequestButtonElement } from '@stripe/react-stripe-js';
import Stripe from './Stripe';
import Braintree from './Braintree';
import PaypalButton from './components/PaypalButton';
import { FireIcon } from '../../components/icons';
import { useSendEvents } from '../../hooks/useSendEvents';
import { useDelayedExecute } from '../../hooks/useDelayedExecute';
import { ExclusiveOffer } from './components/ExclusiveOffer';

const isStage = process.env.REACT_APP_ENV === 'stage';
const textAlign = navigator.language.startsWith('ar') ? 'right' : 'left';
const enablePaypal = process.env.REACT_APP_ENABLE_PAYPAL === 'true';

const ArrowLeftIcon = (
  <svg width="8" height="14" viewBox="0 0 8 14" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 14C6.708 14 6.418 13.873 6.22 13.627L0.22066 7.62698C-0.07734 7.25598 -0.07334 6.72598 0.23166 6.35998L6.23166 0.359984C6.58466 -0.0640163 7.21566 -0.121016 7.64066 0.231984C8.06466 0.584984 8.12166 1.21598 7.76766 1.63998L2.29266 7.01098L7.779 12.373C8.125 12.803 8.057 13.433 7.626 13.779C7.442 13.928 7.22 14 7 14Z"
      fill="#323232"
    />
  </svg>
);

const CheckoutWrapper = styled.div`
  max-width: 330px;
`;

const CheckoutHeadWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 24px;
  width: 100%;
  position: relative;
  margin-top: 24px;
  ${({ $isIntroOffer2 }) =>
    $isIntroOffer2 &&
    css`
      margin-bottom: 12px;
    `}
`;

const StyledBackButton = styled.button`
  border: none;
  background-color: transparent;
  padding: 4px;
  position: absolute;
  left: 5px;
  top: 3px;
  cursor: pointer;
  ${({ $isIntroOffer2 }) =>
    $isIntroOffer2 &&
    css`
      top: 6px;
    `}
`;

const Title = styled.div`
  font-weight: ${(props) => props.theme.fontWeights.extraBold};
  font-size: ${(props) => props.theme.fontSizes.font28};
  line-height: ${(props) => props.theme.lineHeights.lineHeight24};
  color: ${(props) => props.theme.colors.colorText};
  max-width: 280px;
  ${({ $isIntroOffer2 }) =>
    $isIntroOffer2 &&
    css`
      font-weight: 700;
      font-size: 24px;
      line-height: 32px;
    `}
`;

export const PaymentRequestContainer = styled.div`
  width: 100%;
  max-width: 330px;
  margin-bottom: ${({ $mb }) => ($mb ? $mb : '0px')};
  ${({ isButtonsOnTop }) =>
    isButtonsOnTop &&
    css`
      margin-bottom: 0px;
      margin-top: 24px;
    `}
  div iframe {
    border-radius: 12px;
  }
`;

export const CardIconsList = styled.ul`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
  margin-bottom: 16px;
  ${({ $isIntroOffer2 }) =>
    $isIntroOffer2 &&
    css`
      margin-top: 0px;
    `}
  img {
    &:not(:last-child) {
      margin-right: 15px;
    }
  }
`;

export const AlternativePaymentButtons = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

const MoneyBack = styled.div`
  margin-top: 16px;
  display: inline-block;
  padding-left: 22px;
  font-weight: ${(props) => props.theme.fontWeights.extraLight};
  font-size: ${(props) => props.theme.fontSizes.font14};
  line-height: ${(props) => props.theme.lineHeights.lineHeight24};
  color: ${(props) => props.theme.colors.colorCheckoutOr};
  position: relative;
  &::before {
    content: url(${'../assets/checkout/money-back.svg'});
    width: 15px;
    height: 16px;
    position: absolute;
    left: -2px;
    top: 0;
  }
`;

export const StyledBtnWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 7px;
`;

export const StyledBtn = styled.button`
  background-color: transparent;
  letter-spacing: -0.128px;
  text-decoration: underline;
  font-weight: ${(props) => props.theme.fontWeights.extraLight};
  font-size: ${(props) => props.theme.fontSizes.font12};
  line-height: ${(props) => props.theme.lineHeights.lineHeight18};
  color: ${(props) => props.theme.colors.colorCheckoutOr};
  display: flex;
  align-items: center;
  cursor: pointer;
  border: none;
  &:first-child {
    margin-right: 20px;
  }
  &:focus,
  &:active {
    outline: none;
  }
  svg {
    &:hover {
      opacity: 0.8;
    }
  }
`;

export const PayWithContainer = styled.div`
  margin-top: 24px;
  margin-bottom: 24px;
  max-width: 330px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ $isIntroOffer2 }) =>
    $isIntroOffer2 &&
    css`
      margin-top: 16px;
      margin-bottom: 16px;
    `}
`;

export const PayWithLine = styled.div`
  max-width: 140px;
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.colorInactivePlanBorder};
`;

export const PayWithCardContainer = styled(PayWithContainer)`
  margin: 20px 0;
`;

export const PayWithCardLine = styled(PayWithLine)`
  width: 70px;
`;

export const PayWithText = styled.div`
  font-weight: ${(props) => props.theme.fontWeights.extraLight};
  font-size: ${(props) => props.theme.fontSizes.font18};
  line-height: ${(props) => props.theme.lineHeights.lineHeight24};
  color: ${(props) => props.theme.colors.colorCheckoutOr};
  text-align: center;
  margin: 0 12px;
`;

const GetVerified = styled.div`
  background-color: #ebf7f6;
  border-radius: 4px;
  padding: 4px 10px;
  color: #00a08b;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
`;

const TotalPrices = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 16px;
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  color: #232323;
  p {
    display: flex;
    gap: 4px;
    color: #5b5b5b;
    span {
      color: #232323;
    }
    #period {
      font-size: 16px;
    }
  }
`;

const JustSaved = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 4px 0 16px;
  gap: 4px;
  color: #e55656;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
`;

export const style = {
  base: {
    color: '#000',
    fontSize: '18px',
    lineHeight: '24px',
    fontWeight: 300,
    fontFamily: 'Open Sans, Segoe UI, sans-serif',
    textAlign,

    '::placeholder': {
      color: '#ADADAD',
      letterSpacing: '1.2px',
      fontSize: '18px',
      lineHeight: '24px',
    },
  },
};

export const CARD_BRANDS = [
  { name: 'visa', width: '70px', height: '21px' },
  { name: 'mastercard', width: '45px', height: '28px' },
  { name: 'amex', width: '51px', height: '24px' },
  { name: 'discover', width: '49px', height: '10px' },
];

export const DefaultCheckout = ({ stripePromise }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const landingType = useSelector(selectLandingType);
  const showError = useSelector(selectShowPaymentError);
  const isFormValid = useSelector(selectFormValidity);
  const eventsData = useSelector(selectEventsData);
  const isSuccess = useSelector(selectSuccess);
  const subscriptionId = useSelector(selectID);
  const [planDetails, setPlanDetails] = useState(null);
  const token = useSelector(selectToken);
  const name = useSelector(selectEmail);
  const plan = useSelector(selectPlan);
  const [load, setLoad] = useState(false);
  const [showOffer, setShowOffer] = useState(false);
  const [paymentRequest, setPaymentRequest] = useState(null);
  const stripeInstance = useRef();
  const card = useRef();
  const expiration = useRef();
  const cvc = useRef();
  const cardholder = useRef(null);
  const braintreeInstance = useRef();
  const braintreeCardInstance = useRef();
  const braintreeThreeDSInstance = useRef();
  const paypal = useRef();
  const analyticsParams = useSelector(selectAnalyticsData);
  const userUuid = useSelector(selectUserUuid);
  const btToken = useSelector(selectBtToken);
  const showCheckout = useSelector(selectShowCheckout);
  const defaultPlanId = useSelector(selectDefaultPlanId);
  const cardFormVisited = useSelector(selectIfCardFormShown);
  const couponDetails = useSelector(selectPlanCoupon);
  const stripeAccountName = useSelector(selectStripeAccountName);
  const paymentProvider = useSelector(selectPaymentProvider);
  const isBraintree = paymentProvider === 'braintree';
  const isIntroOffer2 = landingType === 'intro_offer2';
  const planExtraInfo = useSelector(selectExtraInfo);
  const customScreenId = isIntroOffer2 ? 'checkout_vh' : 'checkout';
  const sendEvents = useSendEvents({
    extraPathname: customScreenId,
  });
  const delayedExecute = useDelayedExecute();
  const offerShown = useSelector(selectOfferShown);
  const isUp =
    ![
      'gz-monthly-intro7d-99-2999',
      'gz-monthly-intro7d-99-2999-eur',
      'gz-monthly-intro7d-10-499-mxn',
    ].includes(plan) && ['intro_offer2'].includes(landingType);

  const renderBrand = (card) => {
    if (!card) return null;
    const brand = CARD_BRANDS.find(
      (item) => item.name === card.name || item.name === card
    );
    if (!brand) return null;
    return (
      <img
        key={brand.name}
        src={`../assets/checkout/${brand.name}.png`}
        alt={brand.name}
        width={brand.width}
        height={brand.height}
      />
    );
  };

  const renderWhiteCard = (card) => {
    if (!card) return null;
    return (
      <img
        key={card.name}
        src={`../assets/checkout/checkout-brands/${card.name}.png`}
        alt={card.name}
        width={45}
        height={28}
      />
    );
  };

  useLayoutEffect(() => {
    if (plan) {
      const details = PLANS[plan];
      setPlanDetails(details);

      const {
        product_id,
        duration,
        description,
        periodPrice,
        price,
        currency,
      } = details;
      const priceWithCoupon = +(
        periodPrice - couponDetails.couponPrice
      ).toFixed(2);
      const planData = {
        value: Math.round(price * 100),
        currency: currency || 'USD',
        price,
        duration,
        description,
        periodPrice: priceWithCoupon,
        product_id,
      };
      dispatch(setEventDataList(planData));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plan]);

  useEffect(() => {
    if (!isBraintree && stripePromise) {
      stripePromise.then((stripeObj) => {
        stripeInstance.current = stripeObj;
        const elements = stripeObj.elements();

        card.current = elements.create('cardNumber', {
          style: style,
          placeholder: '0000  0000  0000  0000',
        });
        expiration.current = elements.create('cardExpiry', {
          style: style,
          placeholder: t('MM/YY'),
        });
        cvc.current = elements.create('cardCvc', {
          style: style,
          placeholder: t('CVV'),
        });

        setLoad(true);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBraintree, stripePromise]);

  useLayoutEffect(() => {
    if (!isBraintree && stripeInstance.current && !!planDetails) {
      setPaymentRequest(null);
      const label = `Geozilla ${planDetails.description} plan`;
      const amount = Math.round(
        (planDetails.periodPrice - couponDetails?.couponPrice) * 100
      );
      const pr = stripeInstance.current.paymentRequest({
        country: 'US',
        currency: planDetails.currency?.toLowerCase() || 'usd',
        total: { label, amount },
        displayItems: [{ label, amount }],
        requestPayerName: true,
        requestPayerEmail: true,
      });

      pr.canMakePayment().then((result) => {
        if (result && cardFormVisited && eventsData) {
          setPaymentRequest(pr);
        }
      });

      pr.on('paymentmethod', async (e) => {
        const successPayment = () => e.complete('success');
        const failedPayment = () => e.complete('fail');

        stripePurchaseClick(e, successPayment, failedPayment);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    stripeInstance.current,
    planDetails,
    cardFormVisited,
    isBraintree,
    eventsData,
  ]);

  const initializeBraintree = (token) => {
    braintree.client.create(
      { authorization: token },
      (clientErr, clientInstance) => {
        if (clientErr) {
          console.error('Error creating client:', clientErr);
          return;
        }

        braintreeInstance.current = clientInstance;

        if (enablePaypal) {
          braintree.paypal.create(
            { client: clientInstance },
            (paypalErr, paypalInstance) => {
              if (paypalErr) {
                console.error('Error creating PayPal:', paypalErr);
                return;
              }

              paypal.current = paypalInstance;
            }
          );
        }
      }
    );
  };

  useEffect(() => {
    if (btToken && (isBraintree || enablePaypal)) {
      initializeBraintree(btToken);
    }
  }, [btToken, isBraintree]);

  const onPayPalClick = () => {
    const localEventsData = {
      payment_method: 'Paypal',
    };
    sendEvents(EVENTS.subBuyTapped, localEventsData);
    sendEvents(EVENTS.BUY_SUBSCRIPTION_TAPPED, localEventsData);
    dispatch(setCheckoutLoader({ show: true, type: 'checkout' }));
    sendEvents(EVENTS.paymentAnimationScreen, localEventsData);
    sendEvents(EVENTS.PAYMENT_ANIMATION, localEventsData);
    window?.ttq?.track('SubmitForm', {});

    paypal.current.tokenize(
      {
        flow: 'vault',
        currency: 'USD',
      },
      (tokenizeErr, payload) => {
        if (tokenizeErr) {
          if (tokenizeErr.type !== 'CUSTOMER') {
            console.error('Error tokenizing:', tokenizeErr);
          }
          dispatch(setCheckoutLoader({ show: false, type: '' }));
          return;
        }

        const extendedEventsData = {
          ...eventsData,
          ...localEventsData,
          screen_name: customScreenId,
        };

        setAmplitudeUserProperties({ 'Payments gateways': 'Braintree' });
        dispatch(setEventDataList(localEventsData));
        sendEvents(EVENTS.subBuyTapped, localEventsData);
        sendEvents(EVENTS.BUY_SUBSCRIPTION_TAPPED, localEventsData);
        dispatch(
          braintreePurchase(
            payload.nonce,
            null,
            null,
            plan,
            token,
            analyticsParams,
            extendedEventsData,
            userUuid,
            couponDetails.couponName
          )
        );
      }
    );
  };

  const onPurchaseClick = () => {
    if (isBraintree) {
      braintreePurchaseClick();
    } else {
      stripePurchaseClick(null);
    }
  };

  const stripePurchaseClick = (
    paymentMethod = null,
    successPayment = null,
    failedPayment = null
  ) => {
    const paymentMethodName = paymentMethod?.walletName || 'Stripe';
    const paymentCardBrandName =
      paymentMethod?.card?.brand || eventsData?.card_brand || 'unknown';
    const paymentMethodObj = paymentMethod
      ? {
          methodName: paymentMethodName,
          stripeNonce: paymentMethod.paymentMethod.id,
        }
      : null;
    const localEventsData = {
      payment_method: paymentMethodName,
      card_brand: paymentCardBrandName,
      stripe_account_name: stripeAccountName,
    };
    const extendedEventsData = {
      ...eventsData,
      ...localEventsData,
      screen_name: customScreenId,
    };
    window?.ttq?.track('SubmitForm', {});
    setAmplitudeUserProperties({
      'Payments gateways': `Stripe [${stripeAccountName}]`,
    });
    dispatch(
      setEventDataList({
        'Payments gateways': `Stripe [${stripeAccountName}]`,
      })
    );
    dispatch(setEventDataList(localEventsData));
    sendEvents(EVENTS.subBuyTapped, localEventsData);
    sendEvents(EVENTS.BUY_SUBSCRIPTION_TAPPED, localEventsData);
    dispatch(
      sendMainPurchaseRequest(
        paymentMethodObj,
        stripeInstance.current,
        card.current,
        isIntroOffer2 ? '' : name,
        plan,
        token,
        analyticsParams,
        extendedEventsData,
        userUuid,
        successPayment,
        failedPayment,
        couponDetails.couponName
      )
    );
  };

  const braintreePurchaseClick = () => {
    const cardNumber = card.current.value;
    const cardExpiration = expiration.current.value;
    const cardCVV = cvc.current.value;

    const onError = (error) => {
      dispatch(setCheckoutLoader({ show: false, type: '' }));
      dispatch(setShowPaymentError({ show: true, text: error.message }));
    };

    const extraEventsData = {
      payment_method: 'Braintree',
    };
    sendEvents(EVENTS.BUY_SUBSCRIPTION_TAPPED, extraEventsData);
    sendEvents(EVENTS.subBuyTapped, extraEventsData);
    dispatch(setCheckoutLoader({ show: true, type: 'checkout' }));
    sendEvents(EVENTS.paymentAnimationScreen, extraEventsData);
    sendEvents(EVENTS.PAYMENT_ANIMATION, extraEventsData);

    braintreeCardInstance.current.tokenize(
      {
        number: cardNumber,
        expirationDate: cardExpiration,
        cvv: cardCVV,
      },
      (error, payload) => {
        if (error) {
          console.error('Payment error', error);
          onError(error);
          return;
        }

        const cardBrand =
          payload?.details?.cardType || eventsData.card_brand || 'unknown';
        const localEventsData = {
          payment_method: 'Braintree',
          card_brand: cardBrand,
        };
        const extendedEventsData = {
          ...eventsData,
          ...localEventsData,
          screen_name: customScreenId,
        };
        window?.ttq?.track('SubmitForm', {});
        setAmplitudeUserProperties({ 'Payments gateways': 'Braintree' });
        dispatch(setEventDataList(localEventsData));
        dispatch(
          braintreePurchase(
            payload.nonce,
            payload.binData,
            braintreeThreeDSInstance.current,
            plan,
            token,
            analyticsParams,
            extendedEventsData,
            userUuid,
            couponDetails.couponName
          )
        );
      }
    );
  };

  const onLinkClick = (type) => {
    if (!type) return;
    const links = {
      terms: {
        url: 'https://geozilla.com/terms-of-use',
      },
      privacy: {
        url: 'https://geozilla.com/privacy-policy',
      },
    };
    sendEvents(`${customScreenId}__click`, {
      link__redirect: links[type]?.label || type,
    });
    window.open(links[type]?.url);
  };

  useLayoutEffect(() => {
    if (showError) {
      if (eventsData?.declineCode === 'insufficient_funds' && !offerShown) {
        setShowOffer(true);
      } else {
        navigate('/checkout/error');
      }
    }
  }, [showError, navigate, eventsData, offerShown]);

  useEffect(() => {
    if (showCheckout && !showError) {
      dispatch(setIfCardFormShown(true));
      sendEvents(EVENTS.CHECKOUT_SHOWN, {
        screen__load: '/checkout',
      });
      sendEvents(EVENTS.CHECKOUT_SCREEN_LOAD);
      sendEvents(EVENTS.onboardingCheckoutShown);
      sendEvents(`${customScreenId}__screen__load`, {
        screen__load: '/checkout',
      });
      window?.ttq?.track('InitiateCheckout', { content_id: plan });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showCheckout, showError, dispatch]);

  useLayoutEffect(() => {
    if (isSuccess) {
      const { periodPrice, currency } = planDetails;
      const finalPrice = periodPrice - couponDetails?.couponPrice;
      const { fbclid, gclid, ttclid } = eventsData;
      sendPurchaseEvent(
        finalPrice,
        currency,
        plan,
        subscriptionId,
        userUuid,
        gclid,
        fbclid ? 'fb' : ttclid ? 'ttq' : ''
      );

      delayedExecute(() =>
        navigate(`/checkout/${isUp ? 'upsell' : 'success'}`)
      );
    }
  }, [
    isSuccess,
    planDetails,
    subscriptionId,
    plan,
    userUuid,
    navigate,
    isUp,
    eventsData,
    couponDetails,
    delayedExecute,
  ]);

  useLayoutEffect(() => {
    if (isSuccess && landingType === 'air') {
      const { periodPrice, description, currency } = planDetails;
      sendRunwayerConversion({
        onboardId: eventsData?.utm?.onboardId || 'test',
        trialDays: 0,
        planType: description,
        planPrice: (periodPrice - couponDetails?.couponPrice).toFixed(2),
        planCurrency: currency || 'USD',
      });
    }
  }, [planDetails, isSuccess, landingType, eventsData, couponDetails]);

  const separatorElement = (
    <PayWithContainer $isIntroOffer2={isIntroOffer2}>
      <PayWithLine />
      <PayWithText>{t('or')}</PayWithText>
      <PayWithLine />
    </PayWithContainer>
  );

  const onBackClick = () => {
    sendEvents(EVENTS.subBuyClosed);
    dispatch(setShowCheckout(false));
    dispatch(setCardholderName(''));
    dispatch(setPlan(defaultPlanId));
    clearFields();
  };

  const clearFields = () => {
    if (
      !(
        card?.current &&
        expiration?.current &&
        cvc?.current &&
        cardholder?.current
      )
    )
      return;
    cardholder.current.value = '';
    if (!isBraintree) {
      const fieldsToClear = [card.current, expiration.current, cvc.current];
      fieldsToClear.forEach((field) => field.clear());
    } else if (braintreeCardInstance.current) {
      ['number', 'expirationDate', 'cvv'].forEach((field) =>
        braintreeCardInstance.current.clear(field)
      );
    }
  };

  const MoneyBackBlock = () => (
    <>
      <MoneyBack>{t('money_back')}</MoneyBack>
      <StyledBtnWrapper>
        <StyledBtn id={'link-to-terms'} onClick={() => onLinkClick('terms')}>
          {t('terms')}
        </StyledBtn>
        <StyledBtn
          id={'link-to-privacy'}
          onClick={() => onLinkClick('privacy')}
        >
          {t('privacy')}
        </StyledBtn>
      </StyledBtnWrapper>
    </>
  );
  const IntroOfferContent = () => {
    const currency = eventsData.currency === 'USD' ? '$' : '€';
    const planExtraPrice = parseFloat(
      planExtraInfo?.price?.replace(/[$€]/g, '')
    );
    const periodPriceWithCoupon = +(
      planDetails?.periodPrice - couponDetails.couponPrice
    ).toFixed(2);
    return (
      <>
        <GetVerified>{planExtraInfo?.phrase}</GetVerified>
        <TotalPrices>
          <h4>{t('total')}:</h4>
          <p>
            <strike>{planExtraInfo?.price}</strike>
            <span>
              {currency}
              {periodPriceWithCoupon}
            </span>
            <span id="period">{planExtraInfo?.period}</span>
          </p>
        </TotalPrices>
        <JustSaved>
          <FireIcon />
          <span
            dangerouslySetInnerHTML={{
              __html: t('just_saved', {
                currency: currency,
                price: (planExtraPrice - periodPriceWithCoupon).toFixed(2),
                percent: Math.round(
                  (1 - periodPriceWithCoupon / planExtraPrice) * 100
                ),
              }),
            }}
          />
        </JustSaved>
      </>
    );
  };

  const purchaseButton = (
    <AppButton
      onClick={() => onPurchaseClick(null)}
      disabled={!isFormValid}
      displayStubBtn={false}
      customId={`${isBraintree ? 'braintree' : 'stripe'}-continue-button`}
      customStyles={isIntroOffer2 && 'height: 56px;'}
      customMargin={isIntroOffer2 && '0'}
    />
  );

  const alternativePaymentButtons = (paymentRequest || paypal.current) && (
    <AlternativePaymentButtons>
      {paypal.current && (
        <>
          {!isIntroOffer2 && separatorElement}
          <PaypalButton id={'paypal-pay-button'} onClick={onPayPalClick} />
          {isIntroOffer2 && separatorElement}
        </>
      )}
      {cardFormVisited && paymentRequest && (
        <>
          {!isIntroOffer2 && separatorElement}
          <Elements stripe={stripePromise}>
            <PaymentRequestContainer $mb={isIntroOffer2 ? '0px' : '24px'}>
              <PaymentRequestButtonElement
                options={{
                  paymentRequest,
                  id: 'apple-google-pay-button',
                  style: {
                    paymentRequestButton: {
                      height: '56px',
                      theme: 'dark',
                      type: 'default',
                    },
                  },
                }}
              />
            </PaymentRequestContainer>
          </Elements>
          {isIntroOffer2 && separatorElement}
        </>
      )}
    </AlternativePaymentButtons>
  );

  return (
    <CheckoutWrapper>
      <CheckoutHeadWrapper $isIntroOffer2={isIntroOffer2}>
        <StyledBackButton
          $isIntroOffer2={isIntroOffer2}
          onClick={onBackClick}
          id={'come-back-button'}
        >
          {ArrowLeftIcon}
        </StyledBackButton>
        <Title $isIntroOffer2={isIntroOffer2}>
          {isIntroOffer2 ? t('checkout_safe') : t('checkout')}
        </Title>
      </CheckoutHeadWrapper>
      {isIntroOffer2 ? <IntroOfferContent /> : null}
      {isIntroOffer2 ? alternativePaymentButtons : null}
      {!isIntroOffer2 && (
        <CardIconsList>{CARD_BRANDS.map(renderBrand)}</CardIconsList>
      )}
      {isIntroOffer2 && !isBraintree && (
        <CardIconsList $isIntroOffer2={isIntroOffer2}>
          {CARD_BRANDS.map(renderWhiteCard)}
        </CardIconsList>
      )}
      {isBraintree ? (
        <Braintree
          btToken={btToken}
          braintreeInstance={braintreeInstance.current}
          cardInstance={braintreeCardInstance}
          threeDSInstance={braintreeThreeDSInstance}
          card={card}
          expiration={expiration}
          cvc={cvc}
          cardholder={isIntroOffer2 ? null : cardholder}
          planDetails={planDetails}
          separatorElement={separatorElement}
          isButtonsOnTop={isIntroOffer2}
          enablePaypal={enablePaypal}
          isIntroOffer2={isIntroOffer2}
          renderWhiteCard={renderWhiteCard}
        >
          {purchaseButton}
        </Braintree>
      ) : (
        load && (
          <>
            <Stripe
              card={card.current}
              expiration={expiration.current}
              cvc={cvc.current}
              inputRef={isIntroOffer2 ? null : cardholder}
              renderBrand={renderBrand}
            />
          </>
        )
      )}
      {!isBraintree && purchaseButton}
      {!isIntroOffer2 ? alternativePaymentButtons : null}
      <MoneyBackBlock />
      {isStage && (
        <input
          type={'hidden'}
          id={'test-skip-checkout'}
          onClick={() => navigate('/checkout/success')}
        />
      )}
      {showOffer && (
        <ExclusiveOffer
          onContinueClick={onPurchaseClick}
          onPayPalClick={onPayPalClick}
          paymentMethod={eventsData?.payment_method}
          currency={eventsData?.currency || 'USD'}
        />
      )}
    </CheckoutWrapper>
  );
};
