import styled from 'styled-components';
import useWindowSize from '../hooks/useWindowSize';
import { browserName } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { onLinkClick } from '../pages/welcome/WelcomeScreen';
import { PasswordIcon, ProtectionIcon } from './icons';
import { StyledBtn, StyledBtnWrapper } from '../pages/checkout/DefaultCheckout';

const Button = styled.button`
  width: 100%;
  height: 44px;
  min-height: 44px;
  max-width: ${({ customWidth }) => customWidth || '327px'};
  background-color: ${({ customColor, theme }) =>
    customColor || theme.colors.colorPrimary};
  border-radius: 12px;
  border: none;
  margin: ${({ customMargin }) => customMargin || '24px 0 0'};
  font-weight: ${(props) => props.theme.fontWeights.light};
  font-size: ${(props) => props.theme.fontSizes.font16};
  line-height: ${(props) => props.theme.lineHeights.lineHeight19};
  color: ${({ customFontColor, theme }) =>
    customFontColor || theme.colors.colorWhite};
  position: relative;
  z-index: ${({ zIndex }) => zIndex || 'initial'};
  transition: background-color 0.3s;
  ${({ customStyles }) => customStyles};

  &:focus {
    outline: none;
  }

  &:hover {
    opacity: 0.9;
    cursor: pointer;
  }

  &:disabled {
    opacity: 0.5;

    &:hover {
      cursor: default;
    }
  }
`;

const ButtonStub = styled.div`
  height: 56px;
  width: 100%;
  margin-bottom: 16px;
`;

const StickyWrapper = styled.div`
  --bottom-gap: 25px;

  position: ${({ position }) => position || 'fixed'};
  left: auto;
  bottom: ${({ bottomPosition }) => bottomPosition};
  width: 100%;
  z-index: ${({ zIndex }) => zIndex || 'initial'};
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (min-width: 450px) {
    position: ${({ desktopBtnPosition }) => desktopBtnPosition || 'sticky'};
    top: unset;
    align-items: ${({ alignStickyBtn }) => alignStickyBtn || 'center'};
  }

  &:before {
    content: '';
    display: block;
    width: 100vw;
    height: ${({ customBeforeHeight, bottomPosition }) =>
      customBeforeHeight
        ? `${customBeforeHeight}` || '0px'
        : `calc(100% + var(--bottom-gap) + ${bottomPosition})`};
    position: absolute;
    left: calc(-50vw + 50%);
    top: -10px;
    background: ${({ customBeforeBg }) =>
      customBeforeBg ||
      `linear-gradient(0deg,#fff 0%,#fff 75%,rgba(255,255,255,0.5) 85%,rgba(255,255,255,0.35) 95%,rgba(255,255,255,0) 100%)`};
    pointer-events: none;
  }
`;

const Hint = styled.div`
  font-size: 12px;
  line-height: 14px;
  letter-spacing: -0.024em;
  text-align: center;
  color: #afafaf;
  margin: 14px 0 0;
`;

const StyledDisclaimer = styled.div`
  font-size: ${(props) => props.theme.fontSizes.font12};
  color: ${({ $color }) => $color};
  text-align: center;
  padding: 10px 10px 0;
  max-width: 400px;

  span {
    text-decoration: underline;
    cursor: pointer;
  }
`;

const StyledBtnWelcomeWrapper = styled(StyledBtnWrapper)`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 10px;
  color: rgb(175, 175, 175);
`;

export const TopLabel = styled.div`
  display: block;
  font-size: 18px;
  font-style: italic;
  font-weight: 400;
  line-height: 28px;
  color: rgba(132, 132, 132, 1);
  margin-bottom: 32px;
  margin-top: 16px;
  text-align: center;
  @media screen and (max-height: 700px) {
    display: none;
    margin-bottom: 8px;
  }
`;

const TrustLabels = styled.div`
  display: flex;
  margin-top: 10px;
  gap: 10px;
  max-width: 300px;
  position: relative;
  width: 100%;
`;

const TrustItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(229, 248, 246, 1);
  border-radius: 4px;
  gap: 6px;
  padding: 6px 8px;
`;

const TrustText = styled.p`
  font-size: 10px;
  font-weight: 700;
  color: rgba(0, 160, 139, 1);
`;

const AppButton = ({
  onClick,
  children,
  customText,
  disabled,
  customBeforeBg,
  customBeforeHeight = true,
  customColor,
  customFontColor,
  customWidth,
  zIndex,
  customId,
  position,
  displayStickyBtn = true,
  displayStubBtn = true,
  bottomPosition,
  stickyProps,
  alignStickyBtn,
  customMargin,
  withHint,
  withAddress = true,
  customStyles,
  paywallType,
  withTopLabel = null,
  withSecureLabels = false,
  desktopBtnPosition,
}) => {
  const { t } = useTranslation();
  const customInner = customText || 'continue';
  const windowSize = useWindowSize();
  const { innerWidth } = windowSize;
  const onClickFunc = () => {
    if (disabled) return;
    onClick();
  };

  const btn = (
    <>
      {withTopLabel && <TopLabel>{t(withTopLabel)}</TopLabel>}
      <Button
        onClick={onClickFunc}
        disabled={disabled}
        customBeforeBg={customBeforeBg}
        customBeforeHeight={customBeforeHeight}
        customColor={customColor}
        customFontColor={customFontColor}
        customMargin={customMargin}
        customWidth={customWidth}
        zIndex={zIndex}
        id={customId || 'continue-button'}
        customStyles={customStyles}
      >
        {children || t(customInner)}
      </Button>
      {withHint && (
        <>
          {withAddress && <Hint>{t('us_address')}</Hint>}
          <StyledDisclaimer
            dangerouslySetInnerHTML={{
              __html:
                paywallType === 'full2'
                  ? t('welcome_disclaimer_full2')
                  : t('welcome_disclaimer_v2'),
            }}
            $color={paywallType === 'full2' ? '#848484' : '#afafaf'}
            onClick={(e) => onLinkClick(e, paywallType)}
          />
          {paywallType === 'fullPrice' && (
            <StyledBtnWelcomeWrapper>
              <StyledBtn
                onClick={(e) => onLinkClick(e, paywallType)}
                id={'terms'}
              >
                {t('terms')}
              </StyledBtn>
              <StyledBtn
                onClick={(e) => onLinkClick(e, paywallType)}
                id={'privacy'}
              >
                {t('privacy')}
              </StyledBtn>
            </StyledBtnWelcomeWrapper>
          )}
        </>
      )}
    </>
  );

  return displayStickyBtn && (bottomPosition || stickyProps) ? (
    <>
      <StickyWrapper
        desktopBtnPosition={desktopBtnPosition}
        position={position}
        bottomPosition={bottomPosition}
        zIndex={zIndex}
        customBeforeBg={customBeforeBg}
        customBeforeHeight={customBeforeHeight}
        alignStickyBtn={alignStickyBtn}
        {...stickyProps}
      >
        {btn}
        {withSecureLabels && (
          <TrustLabels>
            <TrustItem>
              <ProtectionIcon />
              <TrustText>{t('confidential')}</TrustText>
            </TrustItem>
            <TrustItem>
              <PasswordIcon />
              <TrustText>{t('secure_ssl')}</TrustText>
            </TrustItem>
          </TrustLabels>
        )}
      </StickyWrapper>
      {(((browserName === 'Facebook' || browserName === 'Instagram') &&
        displayStubBtn) ||
        (innerWidth <= 450 && displayStubBtn)) && <ButtonStub />}
    </>
  ) : (
    btn
  );
};

export default AppButton;
