import { sendAmplitudeData, setAmplitudeUserId } from './amplitude';
import { sendFirebaseEvent, setUserId } from './firebase';
import { sendGismartData } from './gismart';
import { sendLockerStudioData } from './lockerStudio';
import gismartEvents from './gismartEvents';
import ReactGA from 'react-ga4';
import {
  REACT_APP_GOOGLE_TRACKING_ID,
  REACT_APP_GOOGLE_ADS_ID,
  GOOGLE_ADS_CONVERSION_ID,
} from './GA';
import { fetchWrapper } from '../utils';

const isStage = process.env.REACT_APP_ENV === 'stage';
const REACT_APP_RUNWAYER_URL = process.env.REACT_APP_RUNWAYER_URL;

export const sendAnalyticsEvents = (eventName, eventParams = {}) => {
  const gismartEventName = gismartEvents[eventName];

  sendAmplitudeData(eventName, eventParams);
  sendFirebaseEvent(eventName, eventParams);
  sendGismartData(gismartEventName || eventName, eventParams);
  sendLockerStudioData(gismartEventName || eventName, eventParams);
};

export const setAnalyticsUserId = (userId = '') => {
  setAmplitudeUserId(userId);
  setUserId(userId);
};

export const pushFBEvent = (data, eventId) => {
  if (window.fbq) {
    window.fbq('track', `Purchase${isStage ? '_test' : ''}`, data, {
      eventID: eventId,
    });
  }
};

export const sendPurchaseEvent = (
  price,
  currency,
  plan,
  subId,
  uuid,
  gclid = '',
  pixelName
) => {
  if (pixelName === 'fb') {
    pushFBEvent(
      {
        currency: currency || 'USD',
        itemCount: 1,
        transactionId: subId,
        price: price,
        value: price,
      },
      `${uuid}_purchase`
    );
  } else if ('ttq') {
    window?.ttq?.track('CompletePayment', {
      value: price,
      currency: currency || 'USD',
      contents: [
        {
          content_type: 'product',
          content_name: plan,
        },
      ],
      event_id: `${uuid}_purchase`,
    });
  }
  if (!isStage) {
    ReactGA.event('purchase', {
      currency: currency || 'USD',
      value: price,
      transaction_id: subId,
      gclid,
      items: {
        item_id: plan,
        item_name: plan,
      },

      send_to: REACT_APP_GOOGLE_TRACKING_ID,
    });
    ReactGA.event('conversion', {
      send_to: `${REACT_APP_GOOGLE_ADS_ID}/${GOOGLE_ADS_CONVERSION_ID}`,
      value: price,
      currency: currency || 'USD',
      transaction_id: subId,
    });
  }
};

export const sendRunwayerConversion = (body) => {
  fetchWrapper(REACT_APP_RUNWAYER_URL, { body })
    .then((data) => console.log(data))
    .catch((err) => console.log(err));
};
