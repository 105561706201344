export const EVENTS = {
  onboardingStarted: 'OnboardingStarted',
  onboardingStartedSubmitted: 'OnboardingStartedSubmitted',
  onboardingMassmediaShown: 'OnboardingMassmediaShown',
  onboardingMassmediaSubmitted: 'OnboardingMassmediaSubmitted',
  onboardingRoleShown: 'OnboardingRoleShown',
  onboardingRoleSubmitted: 'OnboardingRoleSubmitted',
  onboardingRoleSkip: 'OnboardingRoleSkip',
  onboardingLocationsShown: 'OnboardingLocationsShown',
  onboardingLocationsSubmitted: 'OnboardingLocationsSubmitted',
  onboardingLocationsSkip: 'OnboardingLocationsSkip',
  onboardingReasonShown: 'OnboardingReasonShown',
  onboardingReasonSubmitted: 'OnboardingReasonSubmitted',
  onboardingReasonSkip: 'OnboardingReasonSkip',
  onboardingCheckTimeShown: 'OnboardingCheckTimeShown',
  onboardingCheckTimeSubmitted: 'OnboardingCheckTimeSubmitted',
  onboardingCheckTimeSkip: 'OnboardingCheckTimeSkip',
  onboardingUpdatesShown: 'OnboardingUpdatesShown',
  onboardingUpdatesSubmitted: 'OnboardingUpdatesSubmitted',
  onboardingUpdatesSkip: 'OnboardingUpdatesSkip',
  onboardingInappropriateShown: 'OnboardingInappropriateShown',
  onboardingInappropriateSubmitted: 'OnboardingInappropriateSubmitted',
  onboardingInappropriateSkip: 'OnboardingInappropriateSkip',
  onboardingQuantityShown: 'OnboardingQuantityShown',
  onboardingQuantitySubmitted: 'OnboardingQuantitySubmitted',
  onboardingQuantitySkip: 'OnboardingQuantitySkip',
  onboardingFeatureListShown: 'OnboardingFeatureListShown',
  onboardingFeatureListSubmitted: 'OnboardingFeatureListSubmitted',
  onboardingFeatureListSkip: 'OnboardingFeatureListSkip',
  OnboardingReceivedAnswersShown: 'OnboardingReceivedAnswersShown',
  onboardingReceivedAnswersSubmitted: 'OnboardingReceivedAnswersSubmitted',
  onboardingCheckLocation: 'OnboardingCheckLocation',
  onboardingCheckLocationSubmitted: 'OnboardingCheckLocationSubmitted',
  onboardingPlacesShown: 'OnboardingPlacesShown',
  onboardingPlacesSubmitted: 'OnboardingPlacesSubmitted',
  onboardingArSearchShown: 'OnboardingArSearchShown',
  onboardingArSearchSubmitted: 'OnboardingArSearchSubmitted',
  onboardingSosShown: 'OnboardingSosShown',
  onboardingSosSubmitted: 'OnboardingSosSubmitted',
  onboardingPlatformsShown: 'OnboardingPlatformsShown',
  onboardingPlatformsSubmitted: 'OnboardingPlatformsSubmitted',
  onboardingEnterEmailShown: 'OnboardingEnterEmailShown',
  onboardingEnterEmailSubmitted: 'OnboardingEnterEmailSubmitted',
  onboardingQuestionPageSubmitted: 'question_page_completed',
  onboardingFactPageSubmitted: 'fact_page_completed',
  LEAD: 'lead',
  signupSuccess: 'AccountCreated',
  creatingProfileShown: 'CreatingProfileShown',
  onboardingPaywallShown: 'OnboardingPaywallShown',
  PAYMENT_SCREEN_LOAD: 'payment_screen_load',
  onboardingPaywallChosen: 'OnboardingPaywallChosen',
  CHECKOUT_SHOWN: 'subs_purchase_show',
  CHECKOUT_SCREEN_LOAD: 'checkout_screen_load',
  onboardingCheckoutShown: 'OnboardingCheckoutShown',
  subBuyTapped: 'SubBuyTapped',
  BUY_SUBSCRIPTION_TAPPED: 'subs_purchase_started',
  SUBSCRIBED: 'subs_purchase_completed',
  PAYMENT_DECLINE: 'subs_purchase_failed',
  subscribed: 'PaywallSubscribed',
  subBuyClosed: 'PaywallClosed',
  CARD_BRAND_CHOSEN: 'card_checkout_chosen',
  cardMethodChosen: 'CardCheckoutChosen',
  PAYMENT_ANIMATION: 'payment_animation_screen',
  paymentAnimationScreen: 'PaymentAnimationScreen',
  onboardingPaymentDecline: 'OnboardingPaymentDecline',
  onboardingDeclineTryAgain: 'OnboardingDeclineTryAgain',
  DECLINE_TRY_AGAIN: 'payment_declined_try_again',
  upsellView: 'UpsellView',
  upsellTapped: 'UpsellTapped',
  upsellTappedClose: 'UpsellCloseTapped',
  upsellPurchased: 'UpsellPurchased',
  UPSELL_SUBSCRIBED: 'upsell_purchase_completed',
  SUCCESS_SCREEN_LOAD: 'success_page_show',
  upsellFailed: 'UpsellPurchasedFailed',
  onboardingSuccessShown: 'OnboardingSuccessShown',
  onboardingDone: 'OnboardingDone',
};
