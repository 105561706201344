import { createSlice } from '@reduxjs/toolkit';

export const infoSlice = createSlice({
  name: 'info',
  initialState: {
    role: '',
    location: null,
    frequency: null,
    gender: null,
    locationType: null,
    features: null,
    reason: null,
  },
  reducers: {
    setRole: (state, action) => {
      state.role = action.payload;
    },
    setLocation: (state, action) => {
      state.location = action.payload;
    },
    setFrequency: (state, action) => {
      state.frequency = action.payload;
    },
    setGender: (state, action) => {
      state.gender = action.payload;
    },
    setLocationType: (state, action) => {
      state.locationType = action.payload;
    },
    setFeatures: (state, action) => {
      state.features = action.payload;
    },
    setReason: (state, action) => {
      state.reason = action.payload;
    },
  },
});

export const {
  setRole,
  setLocation,
  setFrequency,
  setGender,
  setLocationType,
  setFeatures,
  setReason,
} = infoSlice.actions;

export const selectRole = (state) => state.info.role;
export const selectLocation = (state) => state.info.location;
export const selectFrequency = (state) => state.info.frequency;
export const selectGender = (state) => state.info.gender;
export const selectLocationType = (state) => state.info.locationType;
export const selectFeatures = (state) => state.info.features;
export const selectReason = (state) => state.info.reason;

export default infoSlice.reducer;
