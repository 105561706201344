export const PLANS = {
  'gz-1-month-3999': {
    name: 'gz-1-month-3999',
    price: 39.99,
    periodPrice: 39.99,
    description: 'monthly',
    product_id: '1 month - $39.99',
    duration: '1 month',
    currency: 'USD',
    currencySymbol: '$',
  },
  'gz-3-month-5999': {
    name: 'gz-3-month-5999',
    price: 59.99,
    periodPrice: 59.99,
    description: 'quarterly',
    product_id: '3 months - $59.99',
    duration: '3 months',
    currency: 'USD',
    currencySymbol: '$',
  },
  'gz-6-month-panther': {
    name: 'gz-6-month-panther',
    price: 71.76,
    periodPrice: 71.76,
    description: 'semi-annually',
    product_id: '6 months - $71.76',
    duration: '6 months',
    currency: 'USD',
    currencySymbol: '$',
  },
  'gz-monthly-intro7d-6.99-39.99': {
    name: 'gz-monthly-intro7d-6.99-39.99',
    price: 39.99,
    periodPrice: 6.99,
    description: 'monthly',
    product_id: '1 month - $39.99',
    duration: 'week - month',
    currency: 'USD',
    currencySymbol: '$',
  },
  'gz-3-month-6665': {
    name: 'gz-3-month-6665',
    price: 66.65,
    periodPrice: 66.65,
    description: 'quarterly',
    product_id: '3 months - $66.65',
    duration: '3 months',
    currency: 'USD',
    currencySymbol: '$',
  },

  // euro plans
  'gz-monthly-intro7d-699-3999-eur': {
    name: 'gz-monthly-intro7d-699-3999-eur',
    price: 39.99,
    periodPrice: 6.99,
    description: 'monthly',
    product_id: '1 month - €39.99',
    duration: 'week - month',
    currency: 'EUR',
    currencySymbol: '€',
  },
  'gz-monthly-3999-eur': {
    name: 'gz-monthly-3999-eur',
    price: 39.99,
    periodPrice: 39.99,
    description: 'monthly',
    product_id: '1 month - €39.99',
    duration: '1 month',
    currency: 'EUR',
    currencySymbol: '€',
  },
  'gz-3month-5999-eur': {
    name: 'gz-3month-5999-eur',
    price: 59.99,
    periodPrice: 59.99,
    description: 'quarterly',
    product_id: '3 months - €59.99',
    duration: '3 months',
    currency: 'EUR',
    currencySymbol: '€',
  },
  'gz-3month-6665-eur': {
    name: 'gz-3month-6665-eur',
    price: 66.65,
    periodPrice: 66.65,
    description: 'quarterly',
    product_id: '3 months - €66.65',
    duration: '3 months',
    currency: 'EUR',
    currencySymbol: '€',
  },
  'gz-6month-7176-eur': {
    name: 'gz-6month-7176-eur',
    price: 71.76,
    periodPrice: 71.76,
    description: 'semi-annually',
    product_id: '6 months - €71.76',
    duration: '6 months',
    currency: 'EUR',
    currencySymbol: '€',
  },

  // peso plans
  'gz-monthly-intro7d-9900-59900-mxn': {
    name: 'gz-monthly-intro7d-9900-59900-mxn',
    price: 599,
    periodPrice: 99,
    description: 'monthly',
    product_id: '1 month - Mex$599',
    duration: '1 month',
    currency: 'MXN',
    currencySymbol: 'Mex$',
  },
  'gz-monthly-59900-mxn': {
    name: 'gz-monthly-59900-mxn',
    price: 599,
    periodPrice: 599,
    description: 'monthly',
    product_id: '1 month - Mex$599',
    duration: '1 month',
    currency: 'MXN',
    currencySymbol: 'Mex$',
  },
  'gz-3month-89900-mxn': {
    name: 'gz-3month-89900-mxn',
    price: 899,
    periodPrice: 899,
    description: 'quarterly',
    product_id: '3 months - Mex$899',
    duration: '3 months',
    currency: 'MXN',
    currencySymbol: 'Mex$',
  },

  // upsell plans
  'gz-monthly-upsell-599': {
    name: 'gz-monthly-upsell-599',
    price: 5.99,
    periodPrice: 5.99,
    description: 'monthly upsell',
    product_id: '1 month - upsell $5.99',
    duration: '1 month',
  },
  'gz-monthly-upsell-799': {
    name: 'gz-monthly-upsell-799',
    price: 7.99,
    periodPrice: 7.99,
    description: 'monthly upsell',
    product_id: '1 month - upsell $7.99',
    duration: '1 month',
  },
  'gz-monthly-upsell-999': {
    name: 'gz-monthly-upsell-999',
    price: 9.99,
    periodPrice: 9.99,
    description: 'monthly upsell',
    product_id: '1 month - upsell $9.99',
    duration: '1 month',
  },
  'gz-3month-upsell-599': {
    name: 'gz-3month-upsell-599',
    price: 5.99,
    periodPrice: 5.99,
    description: '3 months upsell',
    product_id: '3 months - upsell $5.99',
    duration: '3 months',
  },
  'gz-3month-upsell-799': {
    name: 'gz-3month-upsell-799',
    price: 7.99,
    periodPrice: 7.99,
    description: '3 months upsell',
    product_id: '3 months - upsell $7.99',
    duration: '3 months',
  },
  'gz-3month-upsell-999': {
    name: 'gz-3month-upsell-999',
    price: 9.99,
    periodPrice: 9.99,
    description: '3 months upsell',
    product_id: '3 months - upsell $9.99',
    duration: '3 months',
  },
  'gz-6month-upsell-599': {
    name: 'gz-6month-upsell-599',
    price: 5.99,
    periodPrice: 5.99,
    description: '6 months upsell',
    product_id: '6 months - upsell $5.99',
    duration: '6 months',
  },
  'gz-6month-upsell-799': {
    name: 'gz-6month-upsell-799',
    price: 7.99,
    periodPrice: 7.99,
    description: '6 months upsell',
    product_id: '6 months - upsell $7.99',
    duration: '6 months',
  },
  'gz-6month-upsell-999': {
    name: 'gz-6month-upsell-999',
    price: 9.99,
    periodPrice: 9.99,
    description: '6 months upsell',
    product_id: '6 months - upsell $9.99',
    duration: '6 months',
  },
  'gz-monthly-upsell-599-eur': {
    name: 'gz-monthly-upsell-599-eur',
    price: 5.99,
    periodPrice: 5.99,
    description: 'monthly upsell',
    product_id: '1 month - upsell €5.99',
    duration: '1 month',
    currency: 'EUR',
    currencySymbol: '€',
  },
  'gz-monthly-upsell-799-eur': {
    name: 'gz-monthly-upsell-799-eur',
    price: 7.99,
    periodPrice: 7.99,
    description: 'monthly upsell',
    product_id: '1 month - upsell €7.99',
    duration: '1 month',
    currency: 'EUR',
    currencySymbol: '€',
  },
  'gz-monthly-upsell-999-eur': {
    name: 'gz-monthly-upsell-999-eur',
    price: 9.99,
    periodPrice: 9.99,
    description: 'monthly upsell',
    product_id: '1 month - upsell €9.99',
    duration: '1 month',
    currency: 'EUR',
    currencySymbol: '€',
  },
  'gz-3month-upsell-599-eur': {
    name: 'gz-3month-upsell-599-eur',
    price: 5.99,
    periodPrice: 5.99,
    description: '3 months upsell',
    product_id: '3 months - upsell €5.99',
    duration: '3 months',
    currency: 'EUR',
    currencySymbol: '€',
  },
  'gz-3month-upsell-799-eur': {
    name: 'gz-3month-upsell-799-eur',
    price: 7.99,
    periodPrice: 7.99,
    description: '3 months upsell',
    product_id: '3 months - upsell €7.99',
    duration: '3 months',
    currency: 'EUR',
    currencySymbol: '€',
  },
  'gz-3month-upsell-999-eur': {
    name: 'gz-3month-upsell-999-eur',
    price: 9.99,
    periodPrice: 9.99,
    description: '3 months upsell',
    product_id: '3 months - upsell €9.99',
    duration: '3 months',
    currency: 'EUR',
    currencySymbol: '€',
  },
  'gz-6month-upsell-999-eur': {
    name: 'gz-6month-upsell-999-eur',
    price: 9.99,
    periodPrice: 9.99,
    description: '6 months upsell',
    product_id: '6 months - upsell €9.99',
    duration: '6 months',
    currency: 'EUR',
    currencySymbol: '€',
  },
  'gz-6month-upsell-799-eur': {
    name: 'gz-6month-upsell-799-eur',
    price: 7.99,
    periodPrice: 7.99,
    description: '6 months upsell',
    product_id: '6 months - upsell €7.99',
    duration: '6 months',
    currency: 'EUR',
    currencySymbol: '€',
  },
  'gz-6month-upsell-599-eur': {
    name: 'gz-6month-upsell-599-eur',
    price: 5.99,
    periodPrice: 5.99,
    description: '6 months upsell',
    product_id: '6 months - upsell €5.99',
    duration: '6 months',
    currency: 'EUR',
    currencySymbol: '€',
  },

  // special offer plans
  'gz-monthly-intro7d-99-2999': {
    name: 'gz-monthly-intro7d-99-2999',
    price: 29.99,
    periodPrice: 0.99,
    description: 'monthly',
    product_id: '1 month - $29.99',
    duration: 'week - month',
    currency: 'USD',
    currencySymbol: '$',
  },
  'gz-monthly-intro7d-99-2999-eur': {
    name: 'gz-monthly-intro7d-99-2999-eur',
    price: 29.99,
    periodPrice: 0.99,
    description: 'monthly',
    product_id: '1 month - €29.99',
    duration: 'week - month',
    currency: 'EUR',
    currencySymbol: '€',
  },
  'gz-monthly-intro7d-10-499-mxn': {
    name: 'gz-monthly-intro7d-10-499-mxn',
    price: 499,
    periodPrice: 10,
    description: 'monthly',
    product_id: '1 month - Mex$499',
    duration: 'week - month',
    currency: 'MXN',
    currencySymbol: 'Mex$',
  },
};
