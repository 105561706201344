import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  selectPlanCoupon,
  selectPlan,
  setCouponDetails,
  setDefaultPlanId,
  setDefaultCoupon,
  setOfferShown,
  setPlan,
} from '../../../../store/plans';
import { setShowPaymentError } from '../../../../store/checkout';
import { setEventDataList } from '../../../../store/events';
import { useSendEvents } from '../../../../hooks/useSendEvents';
import { PLANS } from '../../../../analytics/plans';
import * as S from './styled';

const plans = {
  USD: 'gz-monthly-intro7d-99-2999',
  EUR: 'gz-monthly-intro7d-99-2999-eur',
  MXN: 'gz-monthly-intro7d-10-499-mxn',
};

export const ExclusiveOffer = ({
  onContinueClick,
  onPayPalClick,
  currency,
  paymentMethod,
}) => {
  const screenId = 'exclusive_offer';
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const sendEvents = useSendEvents({ extraPathname: screenId });
  const mainPlan = useSelector(selectPlan);
  const { couponName } = useSelector(selectPlanCoupon);
  const [initEventSent, setInitEventStatus] = useState(false);
  const [planDetailsInEvents, setPlanDetailsInEvents] = useState(null);
  const plan = plans[currency];
  const planDetails = PLANS[plan];
  const { currencySymbol, periodPrice, price } = planDetails;
  const onButtonClick = () => {
    if (paymentMethod === 'Paypal') {
      onPayPalClick();
    } else {
      onContinueClick();
    }
    sendEvents(`${screenId}__click`, {
      button__continue: 'continue',
      ...planDetailsInEvents,
    });
    dispatch(setOfferShown(true));
    dispatch(setShowPaymentError({ show: false, text: '' }));
  };

  useEffect(() => {
    const { price, name, ...rest } = planDetails;
    const planData = {
      ...rest,
      price,
      value: Math.round(price * 100),
    };
    setPlanDetailsInEvents(planData);
    dispatch(setDefaultPlanId(mainPlan));
    dispatch(setPlan(name));
    dispatch(setDefaultCoupon(couponName));
    dispatch(
      setCouponDetails({
        couponPrice: 0,
        couponName: '',
      })
    );
    dispatch(setEventDataList(planData));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [planDetails]);

  useEffect(() => {
    if (initEventSent) return;
    if (!planDetailsInEvents) return;
    sendEvents(`${screenId}__screen__load`, {
      screen__load: screenId,
      ...planDetailsInEvents,
    });
    setInitEventStatus(true);
  }, [sendEvents, initEventSent, planDetailsInEvents]);

  return (
    <S.Wrapper>
      <S.Banner>
        <S.BannerIcon
          width="24"
          height="22"
          viewBox="0 0 24 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M23.5205 16.368L15.1123 1.80435C13.7389 -0.60153 10.2659 -0.60153 8.8975 1.80435L0.484347 16.368C-0.889027 18.7739 0.82028 21.7627 3.59173 21.7627H20.3834C23.1549 21.7627 24.8938 18.7443 23.5205 16.368ZM11.9999 18.5318C11.2688 18.5318 10.6611 17.9242 10.6611 17.1931C10.6611 16.4619 11.2688 15.8543 11.9999 15.8543C12.7311 15.8543 13.3387 16.4619 13.3091 17.2276C13.3437 17.9242 12.7014 18.5318 11.9999 18.5318ZM13.2202 9.87662C13.1609 10.9141 13.0967 11.9466 13.0374 12.984C13.0077 13.3199 13.0077 13.6262 13.0077 13.9572C12.9781 14.5056 12.5483 14.9304 11.9999 14.9304C11.4516 14.9304 11.0267 14.5352 10.9921 13.9869C10.9032 12.3714 10.8094 10.7856 10.7204 9.17017C10.6908 8.74532 10.6611 8.31552 10.6266 7.89066C10.6266 7.18916 11.0218 6.61115 11.664 6.42837C12.3062 6.27522 12.9435 6.58151 13.2202 7.18916C13.314 7.40158 13.3437 7.61401 13.3437 7.86102C13.314 8.53783 13.2498 9.2097 13.2202 9.87662Z"
            fill="#C54C4C"
          />
        </S.BannerIcon>
        <S.BannerText>{t('offer_banner')}</S.BannerText>
      </S.Banner>
      <S.Card>
        <S.Header>
          <S.Description1>{t('offer_description_1')}</S.Description1>
          <S.Title>{t('offer_title')}</S.Title>
        </S.Header>
        <S.Content>
          <S.Price>
            <S.PriceValue>
              {currencySymbol}
              {periodPrice}
            </S.PriceValue>
            &nbsp;/&nbsp;{t('week')}
          </S.Price>
          <S.Description2
            dangerouslySetInnerHTML={{ __html: t('offer_description_2') }}
          />
          <S.Button
            onClick={onButtonClick}
            id={'exclusive-offer-continue-button'}
          >
            {t('continue')}
          </S.Button>
          <S.Disclaimer>
            {t('offer_disclaimer', { price, periodPrice })}
          </S.Disclaimer>
        </S.Content>
      </S.Card>
    </S.Wrapper>
  );
};
