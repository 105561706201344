import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import { selectActivePage } from '../store/ui';
import { useTranslation } from 'react-i18next';
import { sendAnalyticsEvents } from '../analytics/common';
import { EVENTS } from '../analytics/events';
import { selectEventsData, setEventData } from '../store/events';
import { useNavigate } from 'react-router-dom';
import { setRole } from '../store/info';
import { selectLandingType } from '../store/plans';
import AppHeaderLogo from './AppHeaderLogo';

const isArabic = navigator.language.startsWith('ar');
const textAlign = isArabic ? 'right' : 'left';

const StyledBarWrapper = styled.div`
  height: 8px;
  width: 100%;
  display: flex;
  flex-direction: ${`row${isArabic ? '-reverse' : ''}`};
  justify-content: center;
  align-items: center;
  margin-top: 24px;
  margin-bottom: 24px;
  ${({ isOptions }) => isOptions && `position: absolute; top: 0;`}
  ${({ $isFull2 }) => $isFull2 && `height: 6px;`}
  
  ${({ $isIntroOffer2, $isFull2 }) =>
    ($isIntroOffer2 || $isFull2) &&
    css`
      margin-top: 8px;
    `}
  ${({ $isFull2, isOptions }) =>
    $isFull2 &&
    isOptions &&
    css`
      margin-top: 45px;
    `}


  @media screen and (max-height: 600px) {
    margin-top: 13px;
    ${({ $isFull2, isOptions }) => $isFull2 && isOptions && `margin-top: 45px;`}
  }

  @media screen and (min-width: 450px) {
    margin-top: 4vh;
    margin-bottom: 4vh;
    ${({ $isFull2 }) => $isFull2 && `margin-top: 45px;`}
  }
`;

const StyledBar = styled.div`
  min-height: 8px;
  width: 284px;
  height: 100%;
  display: flex;
  ${({ $isIntroOffer2 }) => $isIntroOffer2 && 'width: 343px'}
  ${({ $isFull2 }) =>
    $isFull2 &&
    css`
      width: 324px;
      min-height: 6px;
    `}
`;

const SkipBtn = styled.div`
  font-weight: ${(props) => props.theme.fontWeights.extraLight};
  font-size: ${(props) => props.theme.fontSizes.font16};
  line-height: ${(props) => props.theme.lineHeights.lineHeight19};
  color: ${(props) => props.theme.colors.colorText};
  margin-${textAlign}: 26px;
  white-space: nowrap;
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
`;

const StyledBarItem = styled.div`
  flex-grow: 1;
  background-color: ${({ isActive, theme }) =>
    isActive
      ? theme.colors.colorPrimary
      : theme.colors.colorInactiveProgressTrack};
  ${({ isActive }) => isActive && 'box-shadow: 1px 0 0 rgb(0, 191, 165);'};
  transition: background-color 300ms;
  :first-child {
    border-top-left-radius: 29px;
    border-bottom-left-radius: 29px;
  }
  :last-child {
    border-top-right-radius: 29px;
    border-bottom-right-radius: 29px;
  }
  ${({ $isIntroOffer2, $width }) =>
    $isIntroOffer2 &&
    css`
      flex: ${$width};
    `}
`;

const AppProgressBar = ({ isOptions }) => {
  const activePage = useSelector(selectActivePage);
  const [showBar, setShowBar] = useState(false);
  const eventsData = useSelector(selectEventsData);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const landingType = useSelector(selectLandingType);
  const isIntroOffer = landingType === 'intro_offer';
  const isIntroOffer2 = landingType === 'intro_offer2';
  const isFull2 = landingType === 'full2';

  const pagesWithProgressBar = useMemo(() => {
    const introOfferPages = [
      'q1',
      'q2',
      'q3',
      'q4',
      'q5',
      'q6',
      'q7',
      'q8',
      'q9',
      'q10',
      'q11',
      'q12',
      'q13',
      'q14',
      'q15',
      'q16',
      'q17',
      'q18',
      'q19',
      'q20',
      'q21',
      'q22',
    ];

    const regularPages = [
      'choose-target',
      'choose-location',
      'choose-reason',
      'choose-time',
      'choose-frequency',
      'choose-location-type',
      'choose-quantity',
      'choose-feature',
      !isFull2 && 'explore-solutions',
      !isFull2 && 'real-time-location',
      !isFull2 && 'place-alerts',
      !isFull2 && 'ar-search',
      !isFull2 && 'sos-alerts',
      !isFull2 && 'platform-support',
      !(isIntroOffer || isIntroOffer2 || isFull2) && 'signup',
    ].filter(Boolean);

    return isIntroOffer2 ? introOfferPages : regularPages;
  }, [isIntroOffer, isIntroOffer2, isFull2]);

  const activePageIndex = useMemo(
    () => pagesWithProgressBar.indexOf(activePage),
    [activePage, pagesWithProgressBar]
  );

  const getWidthForIndex = (index) => {
    if (index < 3) return 0.1;
    if (index < 8) return 0.07;
    if (index < 15) return 0.03;
    return 0.02;
  };

  useEffect(() => {
    setShowBar(activePageIndex >= 0);
  }, [activePageIndex]);

  const renderProgress = (item, i) => {
    const isActive = i <= activePageIndex;
    const width = getWidthForIndex(i);

    return (
      <StyledBarItem
        isActive={isActive}
        $width={width}
        $isIntroOffer2={isIntroOffer2}
        key={i}
      />
    );
  };

  if (!showBar) return null;

  const pageTransitions = {
    'choose-target': {
      eventId: 'onboardingRoleSkip',
      eventDataKey: 'Role',
      nextPage: '/choose-location',
      setupRole: true,
    },
    'choose-location': {
      eventId: 'onboardingLocationsSkip',
      eventDataKey: 'Locations',
      nextPage: '/choose-reason',
    },
    'choose-reason': {
      eventId: 'onboardingReasonSkip',
      eventDataKey: 'Reason',
      nextPage: '/choose-time',
    },
    'choose-time': {
      eventId: 'onboardingCheckTimeSkip',
      eventDataKey: 'Frequency',
      nextPage: '/choose-frequency',
    },
    'choose-frequency': {
      eventId: 'onboardingUpdatesSkip',
      eventDataKey: 'Updates',
      nextPage: '/choose-location-type',
    },
    'choose-location-type': {
      eventId: 'onboardingInappropriateSkip',
      eventDataKey: 'InappropriateGeos',
      nextPage: '/choose-quantity',
    },
    'choose-quantity': {
      eventId: 'onboardingQuantitySkip',
      eventDataKey: 'Quantity',
      nextPage: '/choose-feature',
    },
    'choose-feature': {
      eventId: 'onboardingFeatureListSkip',
      eventDataKey: 'Features',
      nextPage: '/explore-solutions',
    },
  };

  const handleSkipClick = () => {
    const pageTransition = pageTransitions[activePage];

    if (pageTransition) {
      const { eventId, eventDataKey, nextPage, setupRole } = pageTransition;

      dispatch(setEventData({ id: eventDataKey, data: 'Other' }));
      setupRole && dispatch(setRole('Other'));
      const updatedEventData = {
        ...eventsData,
        [eventDataKey]: 'Other',
      };

      sendAnalyticsEvents(EVENTS[eventId], updatedEventData);
      navigate(nextPage);
    }
  };

  return (
    <>
      {(isFull2 || isIntroOffer2) && (
        <AppHeaderLogo
          isOptions={isFull2 && isOptions}
          withBottomLine={false}
        />
      )}
      <StyledBarWrapper
        isOptions={isOptions}
        $isIntroOffer2={isIntroOffer2}
        $isFull2={isFull2}
      >
        <StyledBar $isIntroOffer2={isIntroOffer2} $isFull2={isFull2}>
          {pagesWithProgressBar.map(renderProgress)}
        </StyledBar>
        {pageTransitions[activePage] && !isFull2 && (
          <SkipBtn id={'skip-button'} onClick={() => handleSkipClick()}>
            {t('skip')}
          </SkipBtn>
        )}
      </StyledBarWrapper>
    </>
  );
};

export default AppProgressBar;
