import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { selectEventsData, setEventDataList } from '../../store/events';
import useDebounce from '../../hooks/useDebounce';
import { EVENTS } from '../../analytics/events';
import { useSendEvents } from '../../hooks/useSendEvents';
import {
  selectShowPaymentError,
  setCardholderName,
  setFormValidity,
} from '../../store/checkout';
import { setError } from '../../store/error';
import { selectLandingType, selectShowCheckout } from '../../store/plans';

const isArabic = navigator.language.startsWith('ar');
const textAlign = isArabic ? 'right' : 'left';

const StyledWrapper = styled.div`
  width: 100%;
  max-width: 327px;
  position: relative;
  z-index: 10;
  margin: 0;
  ${({ $isWide }) => $isWide && 'max-width: 100%; z-index: 1;'}

  & > * {
    position: relative;
    z-index: 10;
  }
`;

const StyledCard = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
  background: ${(props) => props.theme.colors.colorWhite};
  z-index: 10;

  & > * {
    position: relative;
    z-index: 10;
  }
`;

const StyledFieldWrapper = styled.div`
  width: 100%;
  height: 54px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: ${(props) => props.theme.colors.colorCheckoutInputBg};
  border: 1px solid ${({ theme }) => theme.colors.colorInactivePlanBorder};
  border-radius: 12px;
  padding: 0 5px 0 12px;
  &:not(:last-child) {
    margin-bottom: 12px;
  }
`;

const StyledRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  > div {
    width: 47%;
  }
`;

const StyledInput = styled.input`
  width: 100%;
  height: 22px;
  font-weight: ${(props) => props.theme.fontWeights.extraLight};
  font-size: ${(props) => props.theme.fontSizes.font16};
  line-height: ${(props) => props.theme.lineHeights.lineHeight22};
  color: ${(props) => props.theme.colors.colorBlack};
  padding: 16px 12px 16px 0px;
  background-color: transparent;
  border: none;
  text-align: ${textAlign};

  &::-webkit-credit-card-auto-fill-button {
    visibility: hidden;
    display: none !important;
    pointer-events: none;
    position: absolute;
    right: 0;
  }

  &::placeholder {
    font-weight: ${(props) => props.theme.fontWeights.extraLight};
    font-size: ${(props) => props.theme.fontSizes.font16};
    line-height: ${(props) => props.theme.lineHeights.lineHeight22};
    color: ${(props) => props.theme.colors.colorCheckoutName};
  }

  &:focus,
  &:hover,
  &:active {
    outline: none;
  }
`;

const StyledBrandIcon = styled.span`
  position: absolute;
  right: 16px;
  left: 16px;
  ${textAlign}: auto;
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Stripe = ({
  card,
  cvc,
  expiration,
  inputRef,
  renderBrand,
  isWide = false,
}) => {
  const { t } = useTranslation();
  const [cardBrand, setCardBrand] = useState('');
  const [name, setName] = useState('');
  const [isCvv, setIsCvv] = useState(null);
  const [isExp, setIsExp] = useState(null);
  const [isCard, setIsCard] = useState(null);
  const [err, setErr] = useState('');
  const [isValid, setIsValid] = useState(false);
  const dispatch = useDispatch();
  const eventsData = useSelector(selectEventsData);
  const debouncedName = useDebounce(name, 500);
  const debouncedErr = useDebounce(err, 500);
  const debouncedValidity = useDebounce(isValid, 500);
  const showCheckout = useSelector(selectShowCheckout);
  const showError = useSelector(selectShowPaymentError);
  const landingType = useSelector(selectLandingType);
  const isIntroOffer2 = landingType === 'intro_offer2';
  const isFull2 = landingType === 'full2';
  const customScreenId = isIntroOffer2
    ? 'checkout_vh'
    : isFull2
    ? 'payment_b1_ch'
    : 'checkout';
  const sendEvents = useSendEvents({ extraPathname: customScreenId });

  card.on('ready', () => {
    document.querySelectorAll('.__PrivateStripeElement-input').forEach((el) => {
      el.setAttribute('inputmode', 'numeric');
    });
    !(isIntroOffer2 || isFull2) && card.focus();
  });

  useEffect(() => {
    if (showCheckout || !showError) {
      !(isIntroOffer2 || isFull2) && card.focus();
    }
  }, [showCheckout, showError, card, isIntroOffer2, isFull2]);

  card.on('change', (event) => {
    if (event.brand && event.brand !== 'unknown' && event.brand !== cardBrand) {
      setCardBrand(event.brand);
    }
    if (event.empty) {
      setCardBrand('');
    }
    event.complete && expiration.focus();
    fieldEventHandler(event, setIsCard);
  });

  expiration.on('change', (event) => {
    event.complete && cvc.focus();
    fieldEventHandler(event, setIsExp);
  });

  cvc.on('change', (event) => {
    !(isIntroOffer2 || isFull2) && event.complete && inputRef.current?.focus();
    fieldEventHandler(event, setIsCvv);
  });

  useEffect(() => {
    card.mount('#card-number-element');
    expiration.mount('#card-expiry-element');
    cvc.mount('#card-cvc-element');
  }, [card, cvc, expiration]);

  useEffect(() => {
    if (cardBrand && eventsData && eventsData.card_brand !== cardBrand) {
      const localEventData = {
        card_brand: cardBrand,
      };
      dispatch(setEventDataList(localEventData));
      sendEvents(EVENTS.cardMethodChosen, localEventData);
      sendEvents(EVENTS.CARD_BRAND_CHOSEN, localEventData);
    }
  }, [cardBrand, eventsData, dispatch, isIntroOffer2, sendEvents]);

  const fieldEventHandler = (evt, setValidity) => {
    if (evt.error) {
      setErr(evt.error.message);
    }
    setValidity(evt.complete);
  };

  useEffect(() => {
    if (isCard && isCvv && isExp) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [isCard, isCvv, isExp, dispatch]);

  useEffect(() => {
    if (debouncedName) {
      dispatch(setCardholderName(debouncedName));
    }
  }, [debouncedName, dispatch]);

  useEffect(() => {
    if (debouncedErr) {
      dispatch(
        setError({ show: true, type: 'cardValidation', text: debouncedErr })
      );
    }
  }, [debouncedErr, dispatch]);

  useEffect(() => {
    dispatch(setFormValidity(debouncedValidity));
  }, [debouncedValidity, dispatch]);

  return (
    <StyledWrapper $isWide={isWide}>
      <StyledCard>
        <StyledFieldWrapper>
          <div id={'card-number-element'} />
          <StyledBrandIcon>{renderBrand(cardBrand)}</StyledBrandIcon>
        </StyledFieldWrapper>
        <StyledRow>
          <StyledFieldWrapper>
            <div id={'card-expiry-element'} />
          </StyledFieldWrapper>
          <StyledFieldWrapper>
            <div id={'card-cvc-element'} />
          </StyledFieldWrapper>
        </StyledRow>
        {inputRef && (
          <StyledFieldWrapper>
            <StyledInput
              ref={inputRef}
              onChange={(e) => setName(e.target.value)}
              type="text"
              autocomplete="cc-name"
              placeholder={t('name_on_card')}
              id="cardholder-name-element"
            />
          </StyledFieldWrapper>
        )}
      </StyledCard>
    </StyledWrapper>
  );
};

export default Stripe;
