export const { REACT_APP_GOOGLE_TRACKING_ID, REACT_APP_GOOGLE_ADS_ID } =
  process.env;

export const GOOGLE_ADS_OB_START_ID = 'xqHqCJ3o6cEZEJqgyZQB';
export const GOOGLE_ADS_CONVERSION_ID = 'FH0zCJro6cEZEJqgyZQB';
export const GOOGLE_ADS_ACCOUNT_ID = '9203700535-WEB';

export const getGoogleClientID = (cb) => {
  if (window.ga) {
    window.ga('create', REACT_APP_GOOGLE_TRACKING_ID, 'auto');

    window.ga((tracker) => {
      cb(tracker.get('clientId'));
    });
  }
};
