import styled from 'styled-components';

const Desciption = styled.p`
  font-weight: ${(props) => props.theme.fontWeights.extraLight};
  font-size: ${(props) => props.theme.fontSizes.font16};
  line-height: ${(props) => props.theme.lineHeights.lineHeight19};
  color: ${(props) => props.theme.colors.colorText};
  margin: ${({ customMargin }) => customMargin || 0};
  white-space: pre-line;
  ${({ customStyles }) => customStyles};
`;

const AppDescription = ({ children, customMargin, customStyles = '' }) => (
  <Desciption customMargin={customMargin} customStyles={customStyles}>
    {children}
  </Desciption>
);

export default AppDescription;
