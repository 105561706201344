import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled, { css } from 'styled-components';
import { AppButton, AppHeaderLogo } from '../../components';
import { setEventDataList } from '../../store/events';
import { EVENTS } from '../../analytics/events';
import { useNavigate } from 'react-router-dom';
import { setGender } from '../../store/info';
import { useSendEvents } from '../../hooks/useSendEvents';

const isArabic = navigator.language.startsWith('ar');
const textAlign = isArabic ? 'right' : 'left';

const Icon = ({ $color }) => (
  <svg fill="none" height="20" viewBox="0 0 20 20" width="20">
    <path
      clipRule="evenodd"
      d="m14.2955 7.60549-4.56801 6.00001c-.188.247-.479.393-.789.395h-.007c-.307 0-.597-.142-.787-.384l-2.432-3.107c-.34-.434-.264-1.06301.171-1.40301.434-.341 1.064-.265 1.404.171l1.633 2.08601 3.78401-4.96901c.334-.439.961-.525 1.402-.19.439.335.524.962.189 1.401zm-4.295-7.60500172c-5.52301 0-10.00001172 4.47700172-10.00001172 10.00001172 0 5.522 4.47700172 10 10.00001172 10 5.523 0 10-4.478 10-10 0-5.52301-4.477-10.00001172-10-10.00001172z"
      fill={$color}
      fillRule="evenodd"
    />
  </svg>
);

const InactiveIcon = () => (
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none">
    <mask
      id="path-1-outside-1_625_12297"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0.000488281"
      width="22"
      height="22"
      fill="black"
    >
      <rect fill="white" y="0.000488281" width="22" height="22" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 1.00049C5.477 1.00049 1 5.47749 1 11.0005C1 16.5225 5.477 21.0005 11 21.0005C16.523 21.0005 21 16.5225 21 11.0005C21 5.47749 16.523 1.00049 11 1.00049Z"
      />
    </mask>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 1.00049C5.477 1.00049 1 5.47749 1 11.0005C1 16.5225 5.477 21.0005 11 21.0005C16.523 21.0005 21 16.5225 21 11.0005C21 5.47749 16.523 1.00049 11 1.00049Z"
      fill="white"
    />
    <path
      d="M11 0.000488281C4.92472 0.000488281 0 4.9252 0 11.0005H2C2 6.02977 6.02928 2.00049 11 2.00049V0.000488281ZM0 11.0005C0 17.0747 4.92465 22.0005 11 22.0005V20.0005C6.02935 20.0005 2 15.9703 2 11.0005H0ZM11 22.0005C17.0753 22.0005 22 17.0747 22 11.0005H20C20 15.9703 15.9706 20.0005 11 20.0005V22.0005ZM22 11.0005C22 4.9252 17.0753 0.000488281 11 0.000488281V2.00049C15.9707 2.00049 20 6.02977 20 11.0005H22Z"
      fill="#DEDEDE"
      mask="url(#path-1-outside-1_625_12297)"
    />
  </svg>
);

const QuestionTitle = styled.h1`
  font-weight: 700;
  font-size: 24px;
  line-height: 31.2px;
  color: #1e1e1e;
  margin: 0 0 16px 0;
  white-space: break-spaces;
  position: relative;
  ${({ $customStyles }) => $customStyles};
  ${({ $isIntroOffer2 }) =>
    $isIntroOffer2 &&
    css`
      font-size: 22px;
      /* text-align: center; */
      white-space: normal;
    `}
`;

const Wrapper = styled.div`
  transition: border-radius 0.3s ease;
  width: 100%;
  display: flex;
  flex-direction: column;
  max-width: 327px;
  overflow-y: scroll;
  position: relative;
  margin-bottom: ${({ $isMultiple }) => $isMultiple && '75px'};
  -webkit-overflow-scrolling: touch;
  overscroll-behavior: none;
  scroll-behavior: smooth;
  ${({ $isIntroOffer2 }) =>
    $isIntroOffer2 &&
    css`
      max-width: 343px;
    `}
`;

const ContentWrapper = styled.div``;

const StyledList = styled.ul`
  list-style: none;
  width: 100%;
  margin-top: 16px;
`;

const StyledItem = styled.li`
  width: 100%;
  max-height: 56px;
  display: flex;
  flex-direction: row ${isArabic ? '-reverse' : ''};
  align-items: center;
  background-color: ${({ $active }) => ($active ? '#E6F9F6' : '#F2F2F2')};
  padding: 19px 10px 19px 24px;
  border-radius: 12px;
  position: relative;
  cursor: pointer;
  color: #323232;
  > svg {
    position: absolute;
    right: 16px;
  }
  ${({ $multipleActive }) =>
    $multipleActive &&
    css`
      background-color: #e6f9f6;
    `}
  ${({ $multiple }) =>
    $multiple &&
    css`
      padding-right: 48px;
    `}
  ${({ $centered }) =>
    $centered &&
    css`
      padding: 19px;
    `}
  &:not(:last-child) {
    margin-bottom: 8px;
  }
  &:active {
    background-color: #e6f9f6;
    transition: background-color 0.4s ease;
  }
`;

const StyledItemTitle = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 18.4px;
  text-align: ${textAlign};
  white-space: pre-line;
  width: 100%;
  flex-grow: 1;
  ${({ $centered }) =>
    $centered &&
    css`
      text-align: center;
    `}
`;

const QuestionContainer = ({
  title,
  options,
  nextUrl,
  screenName,
  multiple = false,
  centered = false,
  isIntroOffer2 = false,
  eventName,
}) => {
  const dispatch = useDispatch();
  const [chosenOptions, setChosenOptions] = useState(multiple ? [] : '');
  const [initEventSent, setInitEventStatus] = useState(false);
  const customScreenId = isIntroOffer2 && eventName;
  const sendEvents = useSendEvents({
    extraPathname: customScreenId,
  });
  const navigate = useNavigate();

  useEffect(() => {
    if (initEventSent) return;
    if (eventName) {
      sendEvents(`${eventName}__screen__load`, {
        screen__load: `/${screenName}`,
      });
    }
    setInitEventStatus(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventName, screenName, initEventSent]);

  const onOptionClick = (answer) => {
    const localEvents = {
      question: title,
      answer,
    };
    dispatch(setEventDataList(localEvents));
    if (eventName) {
      sendEvents(`${eventName}__click`, {
        ...(multiple
          ? { button__continue: 'continue' }
          : { option__continue: answer }),
      });
    }
    if (eventName && multiple) {
      sendEvents(`${eventName}__click`, {
        option__select: chosenOptions,
      });
    }
    sendEvents(EVENTS.onboardingQuestionPageSubmitted, localEvents);
    if (isIntroOffer2) {
      if (screenName === 'q8') {
        dispatch(setGender(answer));
      }
    }
    if (multiple) {
      navigate(`/${nextUrl}`);
    } else {
      setTimeout(() => {
        navigate(`/${nextUrl}`);
      }, 500);
    }
  };
  const renderOption = (item, i) => {
    const isActive = chosenOptions === item.value;
    const isActiveMultiple = chosenOptions.includes(item.value);
    const handleClick = () => {
      if (multiple) {
        setChosenOptions((prevOptions) => {
          let newOptions;

          if (item.value === 'AllTheAbove') {
            newOptions = isActiveMultiple ? [] : ['AllTheAbove'];
          } else {
            newOptions = isActiveMultiple
              ? prevOptions.filter((option) => option !== item.value)
              : [
                  ...prevOptions.filter((option) => option !== 'AllTheAbove'),
                  item.value,
                ];

            if (newOptions.length === options.length - 1) {
              newOptions = ['AllTheAbove'];
            }
          }

          return newOptions;
        });
      } else {
        setChosenOptions(item.value);
        onOptionClick(item.value, item.name);
      }
    };

    return (
      <StyledItem
        $active={isActive}
        $centered={centered}
        $multipleActive={multiple && isActiveMultiple}
        $multiple={multiple}
        key={item.id}
        onClick={handleClick}
        id={`option-${i + 1}`}
      >
        {item.name && (
          <StyledItemTitle $multiple={multiple} $centered={centered}>
            {item.name}
          </StyledItemTitle>
        )}
        {multiple && !isIntroOffer2 && (
          <Icon $color={isActiveMultiple ? '#00BFA5' : '#bbe1dc'} />
        )}
        {multiple &&
          isIntroOffer2 &&
          (isActiveMultiple ? <Icon $color="#00BFA5" /> : <InactiveIcon />)}
      </StyledItem>
    );
  };

  return (
    <>
      {!centered && !isIntroOffer2 && <AppHeaderLogo />}
      <Wrapper
        id="scrollable-div"
        $isMultiple={multiple}
        $isIntroOffer2={isIntroOffer2}
      >
        <ContentWrapper>
          <QuestionTitle
            $isIntroOffer2={isIntroOffer2}
            $customStyles={`text-align: ${
              centered || (isIntroOffer2 && screenName === 'q1')
                ? 'center'
                : textAlign
            }; word-break: break-word; margin: ${centered && '0px 0px 56px'}`}
          >
            {title}
          </QuestionTitle>
          <StyledList>{options.map(renderOption)}</StyledList>
        </ContentWrapper>
      </Wrapper>
      {multiple && (
        <AppButton
          onClick={() => onOptionClick(chosenOptions)}
          bottomPosition={'24px'}
          disabled={!chosenOptions.length}
          displayStubBtn={false}
          customBeforeHeight="none"
          customBeforeBg="#ffffff"
          customStyles={'min-height:56px'}
        />
      )}
    </>
  );
};

export default QuestionContainer;
