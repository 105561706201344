import React, { Suspense, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { isIOS, isIPad13, osVersion } from 'react-device-detect';
import { AppAlert, AppLoader } from './components';
import GlobalStyle from './theme/globalStyles';
import theme from './theme';
import './i18n';
import { setWebp } from './store/ui';
import { selectUserUuid, setUserToken, setUuid } from './store/signup';
import { selectLandingType } from './store/plans';
import RouterPath from './RouterPath';
import useInlineScript from './hooks/useInlineScript';
import tiktok from './scripts/tiktokPixel';
import hotjar, { HOTJAR_ID } from './scripts/hotjar';
import {
  REACT_APP_GOOGLE_TRACKING_ID,
  REACT_APP_GOOGLE_ADS_ID,
} from './analytics/GA';
import ReactGA from 'react-ga4';
import FacebookPixel from 'react-facebook-pixel';
import { FB_PIXEL_ID } from './analytics/facebook';
import { queryParser } from 'onboarding-initialization';
// import AF from './scripts/appsflyer';
// import oneLinkSmartScript from './scripts/OneLink';

function App() {
  const dispatch = useDispatch();
  const userUuid = useSelector(selectUserUuid);
  const paywallType = useSelector(selectLandingType);
  const isWebp = !((isIOS && osVersion < 14) || isIPad13);
  const { ttclid } = queryParser(window.location.search);
  const tiktokScript = ttclid ? tiktok : '';
  const hotjarScript =
    HOTJAR_ID &&
    ['intro_offer', 'intro_offer2', 'fullPrice'].includes(paywallType)
      ? hotjar
      : '';

  // useInlineScript(AF, 'AppsFlyerSdkObject');
  // useInlineScript(oneLinkSmartScript, 'AF_SMART_SCRIPT');

  useInlineScript(tiktokScript, 'TiktokJelly');

  useInlineScript(hotjarScript, 'hj');

  useEffect(() => {
    const storageToken = window.sessionStorage?.getItem('access_token');

    if (storageToken) {
      dispatch(setUserToken(storageToken));
    }

    dispatch(setUuid());
  }, [dispatch]);

  useEffect(() => {
    dispatch(setWebp(isWebp));
  }, [dispatch, isWebp]);

  useEffect(() => {
    const initOptionsList = [
      { trackingId: REACT_APP_GOOGLE_TRACKING_ID },
      { trackingId: REACT_APP_GOOGLE_ADS_ID },
    ];
    if (REACT_APP_GOOGLE_TRACKING_ID && REACT_APP_GOOGLE_ADS_ID) {
      ReactGA.initialize(initOptionsList);
    }
  }, []);

  useEffect(() => {
    if (!userUuid) return;
    FacebookPixel.init(FB_PIXEL_ID, { external_id: userUuid });
    FacebookPixel.pageView();
  }, [userUuid]);

  return (
    <Suspense fallback={<AppLoader />}>
      <GlobalStyle />
      <ThemeProvider theme={theme}>
        <AppLoader showMessage />
        <BrowserRouter>
          <RouterPath dispatch={dispatch} />
        </BrowserRouter>
      </ThemeProvider>
      <AppAlert />
    </Suspense>
  );
}

export default App;
