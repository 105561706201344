import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { AlarmIcon, TimerIcon } from './icons';

const TimerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 24px;
  background-color: ${(props) => props.theme.colors.colorTimeBg};
  padding: 16px 24px;
  border-radius: 12px;
`;

const TimerTitle = styled.div`
  font-weight: ${(props) => props.theme.fontWeights.medium};
  font-size: ${(props) => props.theme.fontSizes.font20};
  line-height: ${(props) => props.theme.lineHeights.lineHeight24};
  color: ${(props) => props.theme.colors.colorText};
  letter-spacing: -0.2px;
  margin-bottom: 4px;
`;

const TimeWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const TimeItem = styled.div`
  font-weight: ${(props) => props.theme.fontWeights.extraBold};
  font-size: ${(props) => props.theme.fontSizes.font20};
  line-height: ${(props) => props.theme.lineHeights.lineHeight24};
  color: ${(props) => props.theme.colors.colorPrimary};
  border-radius: 8px;
  background-color: ${(props) => props.theme.colors.colorTimeBg};
  position: relative;

  span {
    font-weight: ${(props) => props.theme.fontWeights.extraBold};
    font-size: ${(props) => props.theme.fontSizes.font12};
    line-height: ${(props) => props.theme.lineHeights.lineHeight14};
    margin-left: 1px;
  }

  &:not(:last-child) {
    margin-right: 14px;
    &:after {
      content: ':';
      display: block;
      font-weight: ${(props) => props.theme.fontWeights.light};
      font-size: ${(props) => props.theme.fontSizes.font20};
      line-height: ${(props) => props.theme.lineHeights.lineHeight24};
      position: absolute;
      top: 0;
      right: -10px;
    }
  }
`;

const TimerTime = styled.div`
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #0f0e0e;
`;

const RedTimerTime = styled.div`
  background-color: rgba(229, 86, 86, 1);
  border-radius: 8px;
  padding: 8px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 16px 0 12px;
  span {
    margin-left: 8px;
    color: rgba(255, 255, 255, 1);
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
  }
`;

export const checkNumber = (value) => {
  value = value.toString();
  if (value.length === 1) {
    return `0${value}`;
  }
  return value;
};

export const useTimer = (initialMinutes, resetTimer = false, timerId) => {
  const [minutes, setMinutes] = useState(initialMinutes || 10);
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    const savedMinutes = sessionStorage.getItem(`timerMinutes-${timerId}`);
    const savedSeconds = sessionStorage.getItem(`timerSeconds-${timerId}`);

    if (savedMinutes && savedSeconds) {
      setMinutes(parseInt(savedMinutes, 10));
      setSeconds(parseInt(savedSeconds, 10));
    }
  }, [timerId]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (minutes === 0 && seconds === 0) {
        clearInterval(interval);
        return;
      }

      if (seconds > 0) {
        setSeconds((prevSeconds) => prevSeconds - 1);
      } else {
        setMinutes((prevMinutes) => prevMinutes - 1);
        setSeconds(59);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [minutes, seconds]);

  useEffect(() => {
    sessionStorage.setItem(`timerMinutes-${timerId}`, minutes);
    sessionStorage.setItem(`timerSeconds-${timerId}`, seconds);
  }, [minutes, seconds, timerId]);

  useEffect(
    () => () => {
      if (resetTimer) {
        sessionStorage.removeItem(`timerMinutes-${timerId}`);
        sessionStorage.removeItem(`timerSeconds-${timerId}`);
      }
    },
    [resetTimer, timerId]
  );

  return { minutes, seconds };
};

export const TimerSimple = ({
  initialMinutes = 10,
  resetTimer = true,
  timerId,
}) => {
  const { minutes, seconds } = useTimer(initialMinutes, resetTimer, timerId);

  return <TimerTime>{`${minutes} : ${checkNumber(seconds)}`}</TimerTime>;
};

export const RedTimer = ({
  initialMinutes = 10,
  resetTimer = true,
  alarmIcon = false,
  timerId,
}) => {
  const { minutes, seconds } = useTimer(initialMinutes, resetTimer, timerId);
  const { t } = useTranslation();

  return (
    <RedTimerTime>
      {alarmIcon ? <AlarmIcon /> : <TimerIcon />}
      <span
        dangerouslySetInnerHTML={{
          __html: t('offer_ends', {
            time: `${minutes}:${checkNumber(seconds)}`,
          }),
        }}
      />
    </RedTimerTime>
  );
};

const AppTimer = ({ initialMinutes = 10, withHours = true, timerId }) => {
  const { t } = useTranslation();
  const { minutes, seconds } = useTimer(initialMinutes, false, timerId);

  return (
    <TimerContainer>
      <TimerTitle>{t('limited_offer')}:</TimerTitle>
      <TimeWrapper>
        {withHours && (
          <TimeItem>
            0<span>{t('hours')}</span>
          </TimeItem>
        )}
        <TimeItem>
          {`${minutes.toString().padStart(2, '0')}`}
          <span>{t('minutes')}</span>
        </TimeItem>
        <TimeItem>
          {`${seconds.toString().padStart(2, '0')}`}
          <span>{t('seconds')}</span>
        </TimeItem>
      </TimeWrapper>
    </TimerContainer>
  );
};

export default AppTimer;
