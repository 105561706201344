import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectLoader, selectLoaderType } from '../store/loader';

const StyledSpinner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 2000;

  &:before {
    content: '';
    display: block;
    width: 64px;
    height: 64px;
    background-image: url('../assets/spinner-image.gif');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 250%;
  }
`;

const Message = styled.span`
  font-weight: ${(props) => props.theme.fontWeights.extraLight};
  font-size: ${(props) => props.theme.fontSizes.font16};
  line-height: ${(props) => props.theme.lineHeights.lineHeight19};
  color: ${(props) => props.theme.colors.colorText};
  text-align: center;
  white-space: break-spaces;
  margin-top: 24px;
  max-width: 220px;
`;

const AppLoader = ({ showMessage }) => {
  const { t } = useTranslation();
  const storeShow = useSelector(selectLoader);
  const loaderType = useSelector(selectLoaderType);
  const checkoutLoader = loaderType === 'checkout';
  const loaderText = checkoutLoader
    ? t('loading_text_payment')
    : t('loading_text');

  if (storeShow) {
    return (
      <StyledSpinner id={'loading-element'}>
        {showMessage && <Message>{loaderText}</Message>}
      </StyledSpinner>
    );
  }

  return null;
};

export default AppLoader;
