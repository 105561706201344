import { useState, useEffect, useMemo } from 'react';
import Cookies from 'js-cookie';
import { useSelector } from 'react-redux';
import { selectEventsData } from '../store/events';
import { selectUserUuid } from '../store/signup';
import { FBC_PARAM, FBP_PARAM, FB_PIXEL_ID } from '../analytics/facebook';
import {
  getGoogleClientID,
  REACT_APP_GOOGLE_TRACKING_ID,
  GOOGLE_ADS_ACCOUNT_ID,
} from '../analytics/GA';
import { TIKTOK_PIXEL_ID, TTP_PARAM } from '../analytics/tiktok';

export const useAnalyticsData = () => {
  const [data, setData] = useState({});
  const [cid, setCid] = useState(null);
  const eventsData = useSelector(selectEventsData);
  const userUuid = useSelector(selectUserUuid);
  const { fbclid, gclid, ttclid, appDomain } = eventsData;
  const AFUserID = Cookies.get('afUserId');
  const fbc = Cookies.get(FBC_PARAM);
  const fbp = Cookies.get(FBP_PARAM);
  const ttp = Cookies.get(TTP_PARAM);
  const activePixelData = useMemo(
    () =>
      fbclid
        ? { fb_pixel_id: FB_PIXEL_ID }
        : ttclid
        ? { tt_pixel_id: TIKTOK_PIXEL_ID }
        : {},
    [fbclid, ttclid]
  );
  const activePixelUserData = useMemo(
    () =>
      fbclid
        ? { fbc, fbp, fb_click_id: fbc || fbclid || '' }
        : ttclid
        ? { ttp, tt_click_id: ttclid || '' }
        : {},
    [fbc, fbp, fbclid, ttclid, ttp]
  );

  const encodedSource = encodeURIComponent(appDomain);

  useEffect(() => getGoogleClientID(setCid), []);

  useEffect(() => {
    setData({
      appsflyer_id: AFUserID,
      google_tracking_id: REACT_APP_GOOGLE_TRACKING_ID,
      google_client_id: cid || '',
      google_ads_account_id: GOOGLE_ADS_ACCOUNT_ID,
      ...activePixelData,
      user_data: {
        uuid: userUuid,
        ...activePixelUserData,
        source_url: encodedSource,
        google_click_id: gclid || '',
      },
    });
  }, [
    cid,
    AFUserID,
    userUuid,
    fbc,
    fbp,
    fbclid,
    gclid,
    encodedSource,
    activePixelData,
    activePixelUserData,
  ]);

  return data;
};
