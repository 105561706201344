import styled from 'styled-components';

const Headline = styled.h1`
  font-weight: ${(props) => props.theme.fontWeights.black};
  font-size: ${(props) => props.theme.fontSizes.font28};
  line-height: ${(props) => props.theme.lineHeights.lineHeight33};
  color: ${(props) => props.theme.colors.colorText};
  margin: ${({ customMargin }) => customMargin || '0 0 24px 0'};
  white-space: break-spaces;
  position: relative;
  ${({ customStyles }) => customStyles};
`;

const AppHeadline = ({ children, customMargin, customStyles = '' }) => (
  <Headline customMargin={customMargin} customStyles={customStyles}>
    {children}
  </Headline>
);

export default AppHeadline;
