import { useEffect } from 'react';

function usePreloadImages(images) {
  useEffect(() => {
    const preloadImages = async () => {
      if (!images) {
        return;
      }
      try {
        let imageList = [];
        if (typeof images === 'string') {
          imageList = [images];
        } else if (Array.isArray(images)) {
          imageList = images;
        } else {
          console.error('Invalid parameter type.');
          return;
        }

        const promises = imageList.map(
          (image) =>
            new Promise((resolve, reject) => {
              const img = new Image();
              img.onload = resolve;
              img.onerror = reject;
              img.src = image;
            })
        );

        await Promise.all(promises);
      } catch (error) {
        console.error('Failed to preload images:', error);
      }
    };

    preloadImages();
  }, [images]);
}

export { usePreloadImages };
