import amplitude from 'amplitude-js';

const amplitudeEventsList = [
  'OnboardingPaywallShown',
  'OnboardingPaywallChosen',
  'OnboardingCheckoutShown',
  'SubBuyTapped',
  'PaywallSubscribed',
  'PaywallClosed',
  'CardCheckoutChosen',
  'PaymentAnimationScreen',
  'OnboardingPaymentDecline',
  'OnboardingDeclineTryAgain',
  'UpsellView',
  'UpsellTapped',
  'UpsellCloseTapped',
  'UpsellPurchased',
  'UpsellPurchasedFailed',
  'OnboardingSuccessShown',
  'OnboardingDone',
];

export const initAmplitude = (userId, callback) => {
  amplitude
    .getInstance()
    .init(process.env.REACT_APP_AMPLITUDE_KEY, userId || null, null, () =>
      callback('amplitude')
    );
};

export const setAmplitudeUserId = (userId) => {
  amplitude.getInstance().setUserId(userId);
};

export const sendAmplitudeData = (eventType, eventParams) => {
  if (!amplitudeEventsList.includes(eventType)) return;
  const checkInit = setInterval(() => {
    if (window.sessionStorage.getItem('amplitudeInit') === 'true') {
      amplitude.getInstance().logEvent(eventType, eventParams);
      clearInterval(checkInit);
    }
  }, 500);
};

export const setAmplitudeUserProperties = (properties) => {
  amplitude.getInstance().setUserProperties(properties);
};

export const sendAmplitudeRevenue = (eventParams) => {
  const { price, product_id } = eventParams;
  const revenue = new amplitude.Revenue()
    .setProductId(product_id)
    .setPrice(price);

  amplitude.getInstance().logRevenueV2(revenue);
};
