import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectWebp } from '../store/ui';

const getImagePaths = (basePath, category, extension, suffix = '') =>
  category.map((imageName) => `${basePath}/${imageName}${suffix}.${extension}`);

const getPathsWithPaywall = (basePath, targets, extension, paywall) => {
  const suffix = paywall === 'full2' ? '_full' : '';
  return getImagePaths(basePath, targets, extension, suffix);
};

export default function useImagesSrc(isWebp, paywall) {
  const isSelectWebp = useSelector(selectWebp);
  const basePath = '../assets';
  const extension = isWebp || isSelectWebp ? 'webp' : 'png';

  const press = getImagePaths(
    `${basePath}/press`,
    ['c-net', 'the-new-york-times', 'the-verge'],
    'png'
  );

  const chooseTarget = getPathsWithPaywall(
    `${basePath}/track_target`,
    ['friend', 'grandparent', 'kid', 'other', 'partner', 'sibling'],
    'png',
    paywall
  );

  const chooseLocation = getPathsWithPaywall(
    `${basePath}/which_locations`,
    [
      'bar_restaurant',
      'friends',
      'hobby_classes',
      'home',
      'medical_institution',
      'other',
      'school',
      'unknown_locations',
      'work',
    ],
    'png',
    paywall
  );

  const chooseReason = getPathsWithPaywall(
    `${basePath}/primary_reason`,
    [
      'doubt',
      'keep_kid_away',
      'match_friend_location',
      'other',
      'prevent_lost',
      'safety_concerns',
      'stay_connected',
    ],
    'png',
    paywall
  );

  const chooseTime = getPathsWithPaywall(
    `${basePath}/how_often`,
    ['every_day', 'every_hour', 'never', 'once_a_week', 'rarely'],
    'png',
    paywall
  );

  const chooseFrequency = getPathsWithPaywall(
    `${basePath}/how_frequently`,
    [
      'every_fifteen_minutes',
      'every_five_minutes',
      'every_hour',
      'other',
      'real_time',
    ],
    'png',
    paywall
  );

  const chooseLocationType = getPathsWithPaywall(
    `${basePath}/types_of_locations`,
    [
      'areas_high_crime',
      'bars_casinos',
      'distant_place_from_home',
      'easily_lost_locations',
      'ex_house',
      'friends_homes',
      'high_risk_injury',
      'hotels',
      'nightclubs',
      'other',
      'shopping_malls',
      'unknown_locations',
      'unsafe_parks_playgrounds',
      'unsupervised_public_areas',
    ],
    'png',
    paywall
  );

  const chooseQuantity = getImagePaths(
    `${basePath}/how_many`,
    ['one_two', 'six_ten', 'ten_plus', 'three_five'],
    'png'
  );

  const chooseFeature = getPathsWithPaywall(
    `${basePath}/which_features`,
    [
      'all_the_above',
      'other',
      'place_alerts',
      'real_time_location_tracking',
      'sos_button',
      'ar_search',
    ],
    'png',
    paywall
  );

  const plansImages = getImagePaths(
    `${basePath}/plans`,
    ['app-icon', 'reviewer1', 'reviewer2', 'reviewer3', 'stars'],
    'png'
  );

  const exploreSolutions = [
    `${basePath}/options/explore-solutions.${extension}`,
  ];

  const exploreSolutionsFull = [
    `${basePath}/options/explore-solutions_full.${extension}`,
  ];

  const realTimeLocation = [
    `${basePath}/options/real-time-location.${extension}`,
    `${basePath}/options/small/real-time-location.png`,
  ];

  const placeAlerts = [
    `${basePath}/options/place-alerts.${extension}`,
    `${basePath}/options/small/place-alerts.png`,
  ];

  const arSearch = [
    `${basePath}/options/ar-search.${extension}`,
    `${basePath}/options/small/ar-search.png`,
  ];

  const sosAlerts = [
    `${basePath}/options/sos-alerts.${extension}`,
    `${basePath}/options/small/sos-alerts.png`,
  ];

  const platformSupport = [
    `${basePath}/options/platform-support.${extension}`,
    `${basePath}/options/small/platform-support.png`,
    `${basePath}/options/apple-store.png`,
    `${basePath}/options/google-play.png`,
  ];

  const platformSupportFull = [
    `${basePath}/options/platform-support_full.${extension}`,
    `${basePath}/options/small/platform-support_full.png`,
    `${basePath}/options/apple-store_full.png`,
    `${basePath}/options/google-play_full.png`,
  ];

  const getInitImagesSources = () => ({
    press: press,
    'choose-target': chooseTarget,
    'choose-location': chooseLocation,
    'choose-reason': chooseReason,
    'choose-time': chooseTime,
    'choose-frequency': chooseFrequency,
    'choose-location-type': chooseLocationType,
    'choose-quantity': chooseQuantity,
    'choose-feature': chooseFeature,
    'explore-solutions':
      paywall === 'full2' ? exploreSolutionsFull : exploreSolutions,
    'real-time-location': realTimeLocation,
    'place-alerts': placeAlerts,
    'ar-search': arSearch,
    'sos-alerts': sosAlerts,
    'platform-support':
      paywall === 'full2' ? platformSupportFull : platformSupport,
    plans: plansImages,
  });

  const [imagesSources, setImagesSources] = useState(getInitImagesSources());

  useEffect(() => {
    const imagesSources = getInitImagesSources();
    setImagesSources(imagesSources);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    imagesSources,
  };
}
