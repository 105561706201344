const landingTypesList = [
  '/',
  '/fullPrice',
  '/fullprice_1',
  '/fullprice_2',
  '/air',
  '/intro_offer',
  '/intro_offer2',
  '/full2',
];

export default landingTypesList;
