import React, { useEffect, useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { selectError, setError } from '../store/error';
import { useTranslation } from 'react-i18next';

const StyledAlert = styled.div`
  width: 90%;
  height: auto;
  max-width: 327px;
  min-height: 50px;
  padding: 16px 40px 16px 16px;
  border-radius: 8px;
  background-color: ${({ show }) => (show ? '#FF5B5B' : 'transparent')};
  display: ${({ show }) => (show ? 'block' : 'none')};
  position: absolute;
  left: 50%;
  top: 6%;
  transform: translate(-50%, -20%);
  font-size: 14px;
  line-height: 18px;
  color: #fff;
  z-index: 100;
  transition: opacity 0.15s linear;
`;

const CloseAlert = styled.div`
  content: '×';
  display: block;
  width: 24px;
  height: 24px;
  position: absolute;
  top: 50%;
  right: 16px;
  margin-top: -12px;
  color: #fff;
  line-height: 24px;
  font-size: 24px;
  text-align: center;
  cursor: pointer;
`;

const AppAlert = () => {
  const { t } = useTranslation();
  const error = useSelector(selectError);
  const dispatch = useDispatch();
  const [clear, setClear] = useState(false);

  const clearError = useCallback(
    () => dispatch(setError({ show: false, text: '', type: '' })),
    [dispatch]
  );

  const doesEmailExist =
    error &&
    error.text &&
    typeof error.text === 'string' &&
    (error.text.replace('.', '').toLowerCase() ===
      'the email has already been taken' ||
      error.text === 'exception.userEmailTaken');

  useEffect(() => {
    if (error.show && !clear) {
      var timer = setTimeout(clearError, 3000);
    }
    return () => {
      setClear(false);
      clearTimeout(timer);
    };
  }, [error, clearError, clear]);

  const onCloseClick = () => {
    setClear(true);
    clearError();
  };

  if (error.show && typeof document !== 'undefined' && document.body) {
    return (
      <StyledAlert show={error.show}>
        <p>{doesEmailExist ? t('email_exist') : error.text}</p>
        {error.show && <CloseAlert onClick={onCloseClick}>×</CloseAlert>}
      </StyledAlert>
    );
  } else {
    return null;
  }
};

export default AppAlert;
