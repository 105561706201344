import { createContext, useState } from 'react';
import styled from 'styled-components';

export const StyleContext = createContext();

const StyledContainer = styled.div`
  width: 100%;
  height: ${({ customHeight }) => customHeight || '100%'};
  max-width: ${({ customWidth }) => customWidth || '375px'};
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: ${({ customJustify }) => customJustify || 'flex-start'};
  ${({ customBgColor }) => customBgColor && `background:${customBgColor};`}
  text-align: center;
  padding: 0 24px 24px;
  ${({ isOptions }) => isOptions && 'padding: 0 0 24px; position: relative;'}
  ${({ customStyles }) => customStyles};

  @media screen and (min-width: 450px) {
    ${({ isOptions }) =>
      isOptions &&
      `
        padding-top: calc(8px + (4vh*2));
        max-width: 515px;
        justify-content: center;
      `}
  }

  & > * {
    width: 100%;
  }
`;

const AppContainer = ({
  customWidth,
  customHeight,
  children,
  customJustify,
  customBgColor,
  isOptions,
  customStyles,
}) => {
  const [style, setStyle] = useState({});
  return (
    <StyleContext.Provider value={setStyle}>
      <StyledContainer
        customWidth={isOptions ? 'auto' : customWidth}
        customHeight={customHeight}
        customJustify={customJustify}
        customBgColor={customBgColor}
        isOptions={isOptions}
        customStyles={customStyles || style}
      >
        {children}
      </StyledContainer>
    </StyleContext.Provider>
  );
};

export default AppContainer;
