import styled, { keyframes } from 'styled-components';

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #fff;
  background-image: url(${'../assets/exclusive-offer/bg-sm.png'});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0 16px;
  z-index: 11;

  & > * {
    width: calc(100% - 32px);
    max-width: 343px;
  }

  @media (min-width: 768px) {
    background-image: url(${'../assets/exclusive-offer/bg-m.png'});

    & > * {
      max-width: 560px;
    }
  }

  @media (min-width: 1024px) {
    background-image: url(${'../assets/exclusive-offer/bg-l.png'});
  }
`;

export const Banner = styled.div`
  padding: 12px 0;
  border-radius: 8px;
  background-color: #ffbebe;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 32px;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #323232;
`;

export const BannerIcon = styled.svg`
  margin-right: 12px;
`;

export const BannerText = styled.div``;

export const Card = styled.div`
  margin: 10px 0 32px;
  border-radius: 12px;
  background-color: #f2f2f2;
`;

export const Header = styled.div`
  background-color: #00a08b;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  padding: 16px 16px 0;
  margin-bottom: 12px;
  color: #fff;

  &:after {
    content: '';
    display: block;
    width: calc(100% + 32px);
    height: 24px;
    background-image: url(${'../assets/exclusive-offer/offer-card-after.png'});
    background-repeat: repeat-x;
    background-position: center;
    background-size: 100% auto;
    position: relative;
    top: 12px;
    left: -16px;
  }
`;

export const Description1 = styled.div`
  font-size: 16px;
  line-height: 24px;
  white-space: pre-line;
`;

export const Title = styled.div`
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  margin-top: 24px;
`;

export const Content = styled.div`
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  padding: 0 16px 24px;
`;

export const Price = styled.div`
  padding: 16px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  line-height: 24px;
  color: #767a86;
`;

export const PriceValue = styled.span`
  font-weight: 700;
  font-size: 48px;
  line-height: 56px;
  color: #323232;
  margin-right: 8px;
`;

export const Description2 = styled.div`
  font-size: 18px;
  line-height: 24px;

  @media (min-width: 390px) {
    white-space: pre-line;
  }

  & > span {
    font-weight: 700;
  }
`;

const pulse = keyframes`
  0%, 100% {
    box-shadow: 0 0 0 4px #9BDFD2;
  }
  50% {
    box-shadow: 0 0 0 4px #9BDFD200;
  }
`;

export const Button = styled.div`
  margin: 24px auto 0;
  width: 100%;
  max-width: 400px;
  height: 56px;
  border-radius: 12px;
  background-color: #00bfa5;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
  animation: ${pulse} 2s infinite;
`;

export const Disclaimer = styled.div`
  margin-top: 20px;
  font-size: 12px;
  line-height: 16px;
  color: #767a86;
`;
