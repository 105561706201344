export const ProtectionIcon = () => (
  <svg width="17" height="16" viewBox="0 0 17 16" fill="none">
    <g clipPath="url(#clip0_468_2359)">
      <path
        d="M8.25 16C8.17845 16 8.10695 15.9846 8.04055 15.9541L6.543 15.263C3.32765 13.7788 1.25 10.5318 1.25 6.99052V2.00003C1.25 1.74588 1.44065 1.53202 1.69335 1.50318C2.6809 1.39038 3.67335 1.22508 4.64255 1.01173C5.8047 0.755875 6.96215 0.425075 8.08325 0.028575C8.19115 -0.009525 8.30885 -0.009525 8.41675 0.028575C9.53785 0.425075 10.6953 0.755875 11.8574 1.01173C12.8267 1.22513 13.8191 1.39038 14.8066 1.50318C15.0593 1.53198 15.25 1.74588 15.25 2.00003V6.99052C15.25 10.5318 13.1723 13.7789 9.957 15.263L8.45945 15.9541C8.39305 15.9846 8.3215 16 8.25 16ZM2.25 2.44288V6.99052C2.25 10.1431 4.0996 13.0337 6.9619 14.3548L8.25 14.9493L9.5381 14.3548C12.4004 13.0337 14.25 10.1431 14.25 6.99052V2.44288C13.3753 2.32958 12.5003 2.17703 11.6426 1.98828C10.4981 1.73633 9.35745 1.41408 8.25 1.02978C7.1426 1.41408 6.00195 1.73633 4.8574 1.98828C3.99975 2.17698 3.12475 2.32958 2.25 2.44288Z"
        fill="#00A08B"
      />
      <path
        d="M10.25 11.5H6.25C5.6985 11.5 5.25 11.0515 5.25 10.5V7C5.25 6.4485 5.6985 6 6.25 6H10.25C10.8015 6 11.25 6.4485 11.25 7V10.5C11.25 11.0515 10.8015 11.5 10.25 11.5ZM6.25 7V10.5H10.2507L10.25 7H6.25Z"
        fill="#00A08B"
      />
      <path
        d="M9.75 7H6.75C6.4739 7 6.25 6.7761 6.25 6.5V5.5C6.25 4.3972 7.1472 3.5 8.25 3.5C9.3528 3.5 10.25 4.3972 10.25 5.5V6.5C10.25 6.7761 10.0261 7 9.75 7ZM7.25 6H9.25V5.5C9.25 4.9485 8.8015 4.5 8.25 4.5C7.6985 4.5 7.25 4.9485 7.25 5.5V6Z"
        fill="#00A08B"
      />
    </g>
    <defs>
      <clipPath id="clip0_468_2359">
        <rect width="16" height="16" fill="white" transform="translate(0.25)" />
      </clipPath>
    </defs>
  </svg>
);

export const PasswordIcon = () => (
  <svg width="17" height="16" viewBox="0 0 17 16" fill="none">
    <path
      d="M12.3824 5.76957H12.1218V3.8693C12.1219 1.73577 10.386 0 8.2522 0C6.11851 0 4.38259 1.73577 4.38259 3.8693V5.76957H4.12182C3.36167 5.76957 2.74316 6.38807 2.74316 7.14823V14.6213C2.74316 15.3814 3.36167 15.9999 4.12182 15.9999H12.3824C13.1426 15.9999 13.7611 15.3814 13.7611 14.6213V7.14823C13.7611 6.38807 13.1427 5.76957 12.3824 5.76957ZM5.56429 3.8693C5.56429 2.38736 6.77011 1.18171 8.2522 1.18171C9.7343 1.18171 10.9401 2.38736 10.9401 3.8693V5.76957H5.56429V3.8693ZM12.5794 14.6213C12.5794 14.7281 12.4892 14.8183 12.3824 14.8183H4.12182C4.01507 14.8183 3.92487 14.7281 3.92487 14.6213V7.14823C3.92487 7.04148 4.01507 6.95127 4.12182 6.95127H4.97344H11.531H12.3824C12.4892 6.95127 12.5794 7.04148 12.5794 7.14823V14.6213Z"
      fill="#00A08B"
    />
    <path
      d="M8.25101 9.16284C7.9247 9.16284 7.66016 9.42731 7.66016 9.7537V12.0159C7.66016 12.3423 7.9247 12.6067 8.25101 12.6067C8.57732 12.6067 8.84186 12.3423 8.84186 12.0159V9.7537C8.84186 9.42731 8.57732 9.16284 8.25101 9.16284Z"
      fill="#00A08B"
    />
  </svg>
);

export const TimerIcon = () => (
  <svg width="21" height="20" viewBox="0 0 21 20" fill="none">
    <path
      d="M10.4946 20C4.97163 20 0.494629 15.523 0.494629 10C0.494629 4.477 4.97163 0 10.4946 0C16.0176 0 20.4946 4.477 20.4946 10C20.4946 15.523 16.0176 20 10.4946 20ZM10.4946 18C12.6164 18 14.6512 17.1571 16.1515 15.6569C17.6518 14.1566 18.4946 12.1217 18.4946 10C18.4946 7.87827 17.6518 5.84344 16.1515 4.34315C14.6512 2.84285 12.6164 2 10.4946 2C8.3729 2 6.33807 2.84285 4.83777 4.34315C3.33748 5.84344 2.49463 7.87827 2.49463 10C2.49463 12.1217 3.33748 14.1566 4.83777 15.6569C6.33807 17.1571 8.3729 18 10.4946 18ZM11.4946 10H15.4946V12H9.49463V5H11.4946V10Z"
      fill="white"
    />
  </svg>
);

export const AlarmIcon = () => (
  <svg width="20" height="21" viewBox="0 0 20 21" fill="none">
    <g clipPath="url(#clip0_977_7203)">
      <path
        d="M0.228809 5.66983C-0.0762695 5.36472 -0.0762695 4.87007 0.228809 4.56499L2.4385 2.3553C2.74361 2.05019 3.2383 2.05019 3.54338 2.3553C3.84846 2.66042 3.84846 3.15507 3.54338 3.46015L1.33369 5.66983C1.02861 5.97495 0.533926 5.97495 0.228809 5.66983Z"
        fill="white"
      />
      <path
        d="M18.6663 5.66983L16.4566 3.46015C16.1515 3.15503 16.1515 2.66038 16.4566 2.3553C16.7617 2.05019 17.2564 2.05019 17.5615 2.3553L19.7712 4.56499C20.0762 4.87011 20.0762 5.36476 19.7712 5.66983C19.4661 5.97495 18.9714 5.97495 18.6663 5.66983Z"
        fill="white"
      />
      <path
        d="M11.01 13.7053L9.44754 12.1428C9.30105 11.9963 9.21875 11.7975 9.21875 11.5903V8.46533C9.21875 8.03385 9.56852 7.68408 10 7.68408C10.4315 7.68408 10.7812 8.03385 10.7812 8.46533V11.2667L12.115 12.6004C12.42 12.9055 12.42 13.4002 12.115 13.7053C11.8098 14.0103 11.3152 14.0104 11.01 13.7053Z"
        fill="white"
      />
      <path
        d="M10.7812 3.03197V1.74658H11.5625C11.994 1.74658 12.3438 1.39682 12.3438 0.965332C12.3438 0.533848 11.994 0.184082 11.5625 0.184082H8.4375C8.00602 0.184082 7.65625 0.533848 7.65625 0.965332C7.65625 1.39682 8.00602 1.74658 8.4375 1.74658H9.21875V3.03197C4.85 3.42314 1.40625 7.09127 1.40625 11.5903C1.40625 16.3398 5.2498 20.1841 10 20.1841C14.7496 20.1841 18.5938 16.3404 18.5938 11.5903C18.5938 7.09635 15.1546 3.42354 10.7812 3.03197ZM10 18.6216C6.12297 18.6216 2.96875 15.4674 2.96875 11.5903C2.96875 7.7133 6.12297 4.55908 10 4.55908C13.877 4.55908 17.0312 7.7133 17.0312 11.5903C17.0312 15.4674 13.877 18.6216 10 18.6216Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_977_7203">
        <rect
          width="20"
          height="20"
          fill="white"
          transform="translate(0 0.184082)"
        />
      </clipPath>
    </defs>
  </svg>
);

export const OrangeProtectionIcon = () => (
  <svg width="29" height="29" viewBox="0 0 29 29" fill="none">
    <g clipPath="url(#clip0_271_13870)">
      <path
        d="M14.5 28.3162C14.3748 28.3162 14.2497 28.2893 14.1335 28.2359L11.5128 27.0264C5.88589 24.4291 2.25 18.7468 2.25 12.5496V3.81621C2.25 3.37144 2.58364 2.99721 3.02586 2.94672C4.75408 2.74932 6.49086 2.46004 8.18696 2.08668C10.2207 1.63894 12.2463 1.06004 14.2082 0.366168C14.397 0.299493 14.603 0.299493 14.7918 0.366168C16.7537 1.06004 18.7793 1.63894 20.813 2.08668C22.5091 2.46013 24.2458 2.74932 25.9741 2.94672C26.4163 2.99712 26.7499 3.37144 26.7499 3.81621V12.5496C26.7499 18.7468 23.114 24.4292 17.4873 27.0264L14.8665 28.2359C14.7503 28.2893 14.6251 28.3162 14.5 28.3162ZM4 4.59119V12.5496C4 18.0666 7.2368 23.1252 12.2458 25.437L14.5 26.4774L16.7542 25.437C21.7632 23.1252 25 18.0666 25 12.5496V4.59119C23.4692 4.39292 21.9379 4.12596 20.4371 3.79564C18.4341 3.35473 16.438 2.79079 14.5 2.11827C12.5621 2.79079 10.5659 3.35473 8.56295 3.79564C7.06206 4.12587 5.53081 4.39292 4 4.59119Z"
        fill="#F2994A"
      />
      <path
        d="M18 20.4412H11C10.0349 20.4412 9.25 19.6563 9.25 18.6912V12.5662C9.25 11.601 10.0349 10.8162 11 10.8162H18C18.9651 10.8162 19.75 11.601 19.75 12.5662V18.6912C19.75 19.6563 18.9651 20.4412 18 20.4412ZM11 12.5662V18.6912H18.0013L18 12.5662H11Z"
        fill="#F2994A"
      />
      <path
        d="M17.125 12.5662H11.875C11.3918 12.5662 11 12.1743 11 11.6912V9.94116C11 8.01126 12.5701 6.44116 14.5 6.44116C16.4299 6.44116 18 8.01126 18 9.94116V11.6912C18 12.1743 17.6082 12.5662 17.125 12.5662ZM12.75 10.8162H16.25V9.94116C16.25 8.97604 15.4651 8.19116 14.5 8.19116C13.5349 8.19116 12.75 8.97604 12.75 9.94116V10.8162Z"
        fill="#F2994A"
      />
    </g>
    <defs>
      <clipPath id="clip0_271_13870">
        <rect
          width="28"
          height="28"
          fill="white"
          transform="translate(0.5 0.316162)"
        />
      </clipPath>
    </defs>
  </svg>
);

export const FireIcon = () => (
  <svg width="12" height="16" viewBox="0 0 12 16" fill="none">
    <path
      d="M10.3941 6.0726C10.351 6.01853 10.2986 6.02916 10.2712 6.04019C10.2483 6.0495 10.1956 6.07925 10.2029 6.15382C10.2117 6.24335 10.2166 6.33463 10.2175 6.42516C10.2214 6.80072 10.0708 7.16866 9.8042 7.43463C9.53933 7.69888 9.19095 7.84125 8.82001 7.83713C8.31333 7.83066 7.89308 7.56638 7.60467 7.07285C7.3662 6.66475 7.47101 6.13841 7.58198 5.58113C7.64692 5.25494 7.71408 4.91763 7.71408 4.5966C7.71408 2.09694 6.03364 0.654815 5.03195 0.0176592C5.01123 0.00450293 4.99151 -0.00012207 4.97405 -0.00012207C4.94564 -0.00012207 4.92314 0.0121279 4.91205 0.0196279C4.89055 0.0341904 4.85614 0.0673779 4.8672 0.126128C5.25008 2.15932 4.10808 3.38216 2.89902 4.67678C1.65277 6.01125 0.240234 7.52378 0.240234 10.2517C0.240234 13.4213 2.81889 15.9999 5.98851 15.9999C8.59826 15.9999 10.8992 14.1804 11.584 11.5753C12.0509 9.79891 11.5616 7.53616 10.3941 6.0726ZM6.13192 14.773C5.33823 14.8092 4.58342 14.5245 4.00689 13.9733C3.43655 13.4279 3.10942 12.6668 3.10942 11.8851C3.10942 10.4182 3.6703 9.34132 5.17886 7.91169C5.20355 7.88828 5.22883 7.88088 5.25086 7.88088C5.27083 7.88088 5.28814 7.88697 5.30005 7.89269C5.32514 7.90478 5.36639 7.93472 5.36083 7.99947C5.30689 8.62713 5.30783 9.1481 5.36358 9.54797C5.50608 10.5694 6.2538 11.2557 7.22426 11.2557C7.70008 11.2557 8.1533 11.0766 8.50045 10.7514C8.54073 10.7137 8.58573 10.7185 8.60298 10.7222C8.62583 10.7271 8.65642 10.7411 8.67245 10.7798C8.81639 11.1273 8.88995 11.4962 8.89108 11.8762C8.89567 13.4051 7.65792 14.7046 6.13192 14.773Z"
      fill="#E55656"
    />
  </svg>
);

export const StarIcon = () => (
  <svg width="17" height="17" viewBox="0 0 17 17" fill="none">
    <path
      d="M6.54285 4.28363C7.30724 1.82073 7.68943 0.589287 8.37817 0.507234C8.45913 0.497589 8.54087 0.497589 8.62183 0.507234C9.31057 0.589287 9.69276 1.82073 10.4571 4.28363C10.6691 4.96661 10.7751 5.3081 11.0203 5.51783C11.0519 5.5448 11.0848 5.56987 11.1191 5.59293C11.3854 5.77228 11.7283 5.77228 12.4143 5.77228C14.8879 5.77228 16.1247 5.77228 16.4121 6.43268C16.4459 6.51031 16.4711 6.5917 16.4874 6.67529C16.6257 7.38641 15.6251 8.14748 13.6239 9.66963C13.0689 10.0917 12.7915 10.3028 12.6767 10.6118C12.662 10.6515 12.6494 10.6921 12.639 10.7333C12.5584 11.0539 12.6643 11.3953 12.8763 12.0783C13.6407 14.5412 14.0229 15.7727 13.5118 16.2629C13.4517 16.3205 13.3855 16.3708 13.3146 16.4128C12.7114 16.7703 11.7108 16.0092 9.70958 14.487C9.15464 14.0649 8.87716 13.8539 8.56101 13.8351C8.52037 13.8327 8.47963 13.8327 8.43899 13.8351C8.12283 13.8539 7.84536 14.0649 7.29041 14.487C5.28923 16.0092 4.28863 16.7703 3.68536 16.4128C3.61445 16.3708 3.54832 16.3205 3.48823 16.2629C2.9771 15.7727 3.35929 14.5412 4.12368 12.0783C4.33565 11.3953 4.44164 11.0539 4.36099 10.7333C4.35063 10.6921 4.33804 10.6515 4.32328 10.6118C4.20854 10.3028 3.93106 10.0917 3.37612 9.66963C1.37493 8.14748 0.374334 7.38641 0.512625 6.67529C0.528881 6.5917 0.554142 6.51031 0.587922 6.43268C0.875295 5.77228 2.1121 5.77228 4.5857 5.77228C5.27165 5.77228 5.61463 5.77228 5.88094 5.59293C5.91517 5.56987 5.94814 5.5448 5.97966 5.51783C6.22489 5.3081 6.33088 4.96661 6.54285 4.28363Z"
      fill="#FBA253"
    />
  </svg>
);
