import { createSlice } from '@reduxjs/toolkit';
import { sendAnalyticsEvents } from '../analytics/common';
import { EVENTS } from '../analytics/events';
import {
  getPollingID,
  getSubscriptionID,
  sendStripeReq,
  subscribeToPriceChange,
} from '../utils/checkoutFetch';
import { calcPercent } from '../utils';
import { setEventData, setEventDataList } from './events';
import { setCheckoutLoader } from './loader';
// import { setError } from './error';

export const checkoutSlice = createSlice({
  name: 'checkout',
  initialState: {
    cardholderName: '',
    token: null,
    isSuccess: null,
    isFormValid: null,
    id: null,
    regularPrice: null,
    stripeNonce: '',
    braintreeNonce: '',
    upsellType: 'old',
    upsellData: null,
    showPaymentError: false,
    errorText: '',
    isCardFormShown: false,
  },
  reducers: {
    setCardholderName: (state, action) => {
      state.cardholderName = action.payload;
    },
    setRequestResult: (state, action) => {
      const { isSuccess, id, finalPrice, regularPrice, upsell_type } =
        action.payload;
      const upsellType = ['old', 'new'];

      state.isSuccess = isSuccess;
      state.id = id;
      state.finalPrice = finalPrice;
      state.regularPrice = regularPrice;
      state.upsellType = upsellType[upsell_type || 0];
    },
    setUpsellData: (state, action) => {
      state.upsellData = {
        success: action.payload?.isSuccess,
        finalPrice: action.payload?.finalPrice,
        regularPrice: action.payload?.regularPrice,
      };
    },
    setDiscountPercentage: (state, action) => {
      const { finalPrice, regularPrice } = action.payload;
      state.discountPercentage = calcPercent(
        regularPrice - finalPrice,
        regularPrice
      );
    },
    setFormValidity: (state, action) => {
      state.isFormValid = action.payload;
    },
    setStripeNonce: (state, action) => {
      state.stripeNonce = action.payload;
    },
    setBraintreeNonce: (state, action) => {
      state.braintreeNonce = action.payload;
    },
    setShowPaymentError: (state, action) => {
      const { show, text } = action.payload;
      state.showPaymentError = show;
      state.errorText = text;
    },
    setIfCardFormShown: (state, action) => {
      state.isCardFormShown = action.payload;
    },
  },
});

export const {
  setCardholderName,
  setStripeNonce,
  setBraintreeNonce,
  setRequestResult,
  setDiscountPercentage,
  setFormValidity,
  setUpsellData,
  setShowPaymentError,
  setIfCardFormShown,
} = checkoutSlice.actions;

const sendServerReq = async (
  stripe,
  paymentMethodObj,
  plan,
  token,
  analyticsParams,
  eventsData,
  onError,
  onSuccess,
  eventId,
  upsell,
  payment_intent_id,
  coupon
) => {
  const { stripeNonce, methodName } = paymentMethodObj;
  const pollingID = await getPollingID(
    stripeNonce,
    plan,
    token,
    analyticsParams,
    eventsData,
    onError,
    eventId,
    upsell,
    payment_intent_id,
    coupon
  );
  const purchaseRequest = await subscribeToPriceChange(pollingID);
  const { status, errors, data } = purchaseRequest || {
    errors: { messages: 'Unknown error. Please try again' },
  };

  if (status) {
    if (status === 'requires_action' && data && data.payment_intent_secret) {
      const cardPaymentSecret = data.payment_intent_secret;
      const cardPaymentData = methodName
        ? { payment_method: stripeNonce }
        : { setup_future_usage: 'off_session' };
      const cardPaymentOptions = methodName ? { handleActions: false } : {};

      const { error: errorAction, paymentIntent } =
        await stripe.confirmCardPayment(
          cardPaymentSecret,
          cardPaymentData,
          cardPaymentOptions
        );

      if (errorAction) {
        if (methodName) {
          onError(errorAction.message, data);
        } else {
          await sendServerReq(
            stripe,
            paymentMethodObj,
            plan,
            token,
            analyticsParams,
            eventsData,
            onError,
            onSuccess,
            eventId,
            upsell,
            data.payment_intent_id,
            coupon
          );
        }
        return;
      }
      if (methodName) {
        if (paymentIntent.status === 'requires_action') {
          const { error } = await stripe.confirmCardPayment(
            data.payment_intent_secret
          );
          if (error) {
            onError(error, data);
            return;
          }
        }

        onSuccess();
      }

      await sendServerReq(
        stripe,
        paymentMethodObj,
        plan,
        token,
        analyticsParams,
        eventsData,
        onError,
        onSuccess,
        eventId,
        upsell,
        paymentIntent.id,
        coupon
      );
    } else {
      const {
        subscription_id,
        price_with_discount,
        regular_price,
        upsell_type,
      } = purchaseRequest;

      return onSuccess(
        subscription_id,
        price_with_discount,
        regular_price,
        upsell_type
      );
    }
  }

  if (errors) {
    if (status === 'requires_action') return;

    onError(errors.messages || 'An error has occurred', data);
  }
};

export const sendMainPurchaseRequest =
  (
    paymentMethodObj = null,
    stripe,
    card,
    name,
    plan,
    token,
    analyticsParams,
    eventsData,
    eventId,
    successPayment,
    failedPayment,
    coupon
  ) =>
  async (dispatch) => {
    const onError = (data, errorData) => {
      if (failedPayment) failedPayment();

      let txt = data;
      if (typeof txt !== 'string') txt = data?.message;
      if (typeof txt !== 'string') {
        txt = 'Payment error';
      }

      dispatch(
        setEventData({
          id: 'declineCode',
          data: errorData?.decline_code || errorData?.processor_response,
        })
      );
      sendAnalyticsEvents(EVENTS.onboardingPaymentDecline, {
        ...eventsData,
        declineCode: errorData?.decline_code || errorData?.processor_response,
      });
      sendAnalyticsEvents(EVENTS.PAYMENT_DECLINE, {
        ...eventsData,
        declineCode: errorData?.decline_code || errorData?.processor_response,
      });
      dispatch(setShowPaymentError({ show: true, text: txt }));
      // dispatch(setError({show: true, text: txt, type: 'payment'}));
    };
    const onSuccess = (id, finalPrice, regularPrice, upsell_type) => {
      if (successPayment) successPayment();
      if (finalPrice) {
        dispatch(setDiscountPercentage({ finalPrice, regularPrice }));
      }

      dispatch(
        setRequestResult({
          isSuccess: true,
          id,
          finalPrice,
          regularPrice,
          upsellType: upsell_type,
        })
      );
      sendAnalyticsEvents(EVENTS.subscribed, eventsData);
      sendAnalyticsEvents(EVENTS.SUBSCRIBED, eventsData);
    };

    dispatch(setCheckoutLoader({ show: true, type: 'checkout' }));
    sendAnalyticsEvents(EVENTS.paymentAnimationScreen, eventsData);
    sendAnalyticsEvents(EVENTS.PAYMENT_ANIMATION, eventsData);

    try {
      if (paymentMethodObj) {
        dispatch(setStripeNonce(paymentMethodObj.stripeNonce));
        await sendServerReq(
          stripe,
          paymentMethodObj,
          plan,
          token,
          analyticsParams,
          eventsData,
          onError,
          onSuccess,
          eventId,
          false,
          '',
          coupon
        );
      } else {
        const { paymentMethod, error } = await sendStripeReq(
          stripe,
          card,
          name
        );
        const stripeNonce = paymentMethod?.id || error?.code;

        if (error) {
          console.error(error.message);
          dispatch(setShowPaymentError({ show: true, text: error.message }));
        } else {
          dispatch(setStripeNonce(stripeNonce));
          await sendServerReq(
            stripe,
            { stripeNonce },
            plan,
            token,
            analyticsParams,
            eventsData,
            onError,
            onSuccess,
            eventId,
            false,
            '',
            coupon
          );
        }
      }
    } finally {
      dispatch(setCheckoutLoader({ show: false, type: '' }));
    }
  };

export const sendUpsellPurchaseRequest =
  (
    stripeNonce,
    plan,
    token,
    analyticsParams,
    eventsData,
    eventId,
    onSuccessEvents
  ) =>
  async (dispatch) => {
    function onError() {
      console.log(arguments);
    }
    function onSuccess() {
      if (onSuccessEvents) onSuccessEvents(arguments);
      // console.log(arguments);
    }

    dispatch(setCheckoutLoader({ show: true, type: 'checkout' }));

    try {
      await sendServerReq(
        null,
        { stripeNonce },
        plan,
        token,
        analyticsParams,
        eventsData,
        onError,
        onSuccess,
        eventId,
        true,
        '',
        ''
      );
    } finally {
      dispatch(setCheckoutLoader({ show: false, type: '' }));
    }
  };

export const braintreePurchase =
  (
    nonce,
    binData,
    threeDSInstance,
    plan,
    token,
    analyticsParams,
    eventsData,
    userUuid,
    coupon
  ) =>
  async (dispatch) => {
    dispatch(setBraintreeNonce(nonce));

    const onError = (data, errorData) => {
      let txt = data;
      if (typeof txt !== 'string') txt = data.message;
      if (typeof txt !== 'string') {
        txt = 'Payment error';
      }

      dispatch(
        setEventData({
          id: 'decline_code',
          data: errorData?.decline_code || errorData?.processor_response,
        })
      );
      sendAnalyticsEvents(EVENTS.onboardingPaymentDecline, {
        ...eventsData,
        declineCode: errorData?.decline_code || errorData?.processor_response,
      });
      sendAnalyticsEvents(EVENTS.PAYMENT_DECLINE, {
        ...eventsData,
        declineCode: errorData?.decline_code || errorData?.processor_response,
      });
      dispatch(setShowPaymentError({ show: true, text: txt }));
      dispatch(setCheckoutLoader({ show: false, type: '' }));
    };
    const onSuccess = ({ customer_id, subscription_id }) => {
      const localData = {
        customer_id,
        subscription_id,
        plan_id: plan,
      };
      dispatch(setRequestResult({ isSuccess: true, id: subscription_id }));
      dispatch(setEventDataList(localData));
      sendAnalyticsEvents(EVENTS.subscribed, { ...eventsData, ...localData });
      sendAnalyticsEvents(EVENTS.SUBSCRIBED, {
        ...eventsData,
        ...localData,
      });
      dispatch(setCheckoutLoader({ show: false, type: '' }));
    };

    const purchaseRequest = await getSubscriptionID(
      nonce,
      plan,
      token,
      analyticsParams,
      eventsData,
      userUuid,
      coupon
    );

    try {
      const { errors, data } = purchaseRequest;
      if (errors) {
        if (errors?.messages === 'User action required') {
          const { payment_method_nonce, validation_amount } = data;
          dispatch(setBraintreeNonce(payment_method_nonce));

          threeDSInstance.verifyCard(
            {
              nonce: payment_method_nonce,
              bin: binData,
              amount: validation_amount,
            },
            (verifyError, payload) => {
              if (verifyError) {
                console.error('verify error: ', verifyError);
                onError(verifyError, data);
                return;
              }
              // console.log('verify: ', payload);

              dispatch(
                braintreePurchase(
                  payload.nonce,
                  binData,
                  threeDSInstance,
                  plan,
                  token,
                  analyticsParams,
                  eventsData,
                  userUuid
                )
              );
            }
          );
          return;
        }

        onError(errors?.messages || 'An error has occurred', data);
        return;
      }
      onSuccess(data);
    } catch (err) {
      onError(err);
    }
  };

export const sendUpsellBraintreePurchase =
  (braintreeNonce, plan, token, analyticsParams, eventsData, userUuid) =>
  async (dispatch) => {
    dispatch(setCheckoutLoader({ show: true, type: 'checkout' }));

    try {
      await getSubscriptionID(
        braintreeNonce,
        plan,
        token,
        analyticsParams,
        eventsData,
        userUuid
      );
    } finally {
      dispatch(setCheckoutLoader({ show: false, type: '' }));
    }
  };

export const selectSuccess = (state) => state.checkout.isSuccess;
export const selectName = (state) => state.checkout.cardholderName;
export const selectFormValidity = (state) => state.checkout.isFormValid;
export const selectID = (state) => state.checkout.id;
export const selectStripeNonce = (state) => state.checkout.stripeNonce;
export const selectBraintreeNonce = (state) => state.checkout.braintreeNonce;
export const selectUpsellData = (state) => state.checkout.upsellData;
export const selectUpsellType = (state) => state.checkout.upsellType;
export const selectShowPaymentError = (state) =>
  state.checkout.showPaymentError;
export const selectErrorText = (state) => state.checkout.errorText;
export const selectIfCardFormShown = (state) => state.checkout.isCardFormShown;

export default checkoutSlice.reducer;
