const { REACT_APP_API_URL2 } = process.env;

export const sendStripeReq = async (stripe, card, name) => {
  try {
    return await stripe.createPaymentMethod({
      type: 'card',
      card,
      billing_details: {
        name,
      },
    });
  } catch (e) {
    return e;
  }
};

export const subscribeToPriceChange = async (pollingId) => {
  const url = `${REACT_APP_API_URL2}/polling/get-result/${pollingId}`;

  try {
    const data = await fetch(url, { method: 'GET' });
    const response = await data.json();
    const {
      data: { result_data },
    } = response;

    if (data.status === 202) {
      return await subscribeToPriceChange(pollingId);
    }

    return result_data;
  } catch (e) {
    return e.response?.data?.data?.result_data;
  }
};

export const getPollingID = async (
  nonce,
  plan,
  token,
  analyticsParams,
  eventsData,
  onErrorHandler,
  userUuid,
  upsell,
  payment_intent_id,
  coupon
) => {
  const url = `${REACT_APP_API_URL2}/users/me/subscriptions/polling/${plan}`;

  const requestData = {
    ...analyticsParams,
    user_data: {
      ...analyticsParams['user_data'],
      ...eventsData,
    },
  };

  const body = {
    gateway: 'stripe',
    payment_method_nonce: nonce,
    coupon: coupon || '',
    fb_event_id: `${userUuid}_${upsell ? 'upsell' : 'purchase'}`,
    tt_event_id: `${userUuid}_${upsell ? 'upsell' : 'purchase'}`,
    payment_intent_id,
    ...requestData,
  };

  try {
    const data = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(body),
    });
    const res = await data.json();
    const {
      data: { polling_result_id },
    } = res;

    return polling_result_id;
  } catch (e) {
    onErrorHandler(e.response?.data?.errors?.messages);
  }
};

export const getSubscriptionID = async (
  nonce,
  plan,
  token,
  analyticsParams,
  eventsData,
  userUuid,
  coupon
) => {
  const url = `${REACT_APP_API_URL2}/users/me/subscriptions/${plan}`;

  const requestData = {
    ...analyticsParams,
    user_data: {
      ...analyticsParams['user_data'],
      ...eventsData,
    },
  };

  const body = {
    gateway: 'braintree',
    payment_method_nonce: nonce,
    coupon: coupon || '',
    fb_event_id: `${nonce}_${userUuid}`,
    tt_event_id: `${nonce}_${userUuid}`,
    ...requestData,
  };

  try {
    const results = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(body),
    });

    return await results.json();
  } catch (e) {
    return e.response?.data || { errors: 'An error has occurred' };
  }
};
