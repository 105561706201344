import {
  Navigate,
  useLocation,
  useRoutes,
  useNavigate,
} from 'react-router-dom';
import { AppContainer, AppProgressBar } from './components';
import React, { useCallback, useEffect } from 'react';
import { setActivePage } from './store/ui';
import Welcome from './pages/welcome/WelcomeScreen';
import landingTypesList from './utils/landingTypes';
const Press = React.lazy(() => import('./pages/options/Press'));
const TargetTrack = React.lazy(() => import('./pages/track/TargetTrack'));
const LocationTrack = React.lazy(() => import('./pages/track/LocationTrack'));
const ReasonTrack = React.lazy(() => import('./pages/track/ReasonTrack'));
const TimeTrack = React.lazy(() => import('./pages/track/TimeTrack'));
const FrequencyTrack = React.lazy(() => import('./pages/track/FrequencyTrack'));
const LocationTypeTrack = React.lazy(() =>
  import('./pages/track/LocationTypeTrack')
);
const QuantityTrack = React.lazy(() => import('./pages/track/QuantityTrack'));
const FeatureTrack = React.lazy(() => import('./pages/track/FeatureTrack'));
const ExploreSolutions = React.lazy(() =>
  import('./pages/options/ExploreSolutions')
);
const RealTimeLocation = React.lazy(() =>
  import('./pages/options/RealTimeLocation')
);
const PlaceAlerts = React.lazy(() => import('./pages/options/PlaceAlerts'));
const ArSearch = React.lazy(() => import('./pages/options/ArSearch'));
const SosAlerts = React.lazy(() => import('./pages/options/SosAlerts'));
const PlatformSupport = React.lazy(() =>
  import('./pages/options/PlatformSupport')
);
const Summary = React.lazy(() => import('./pages/track/Summary'));
const ReadyPlan = React.lazy(() => import('./pages/plans/ReadyPlan'));
const SignupScreen = React.lazy(() => import('./pages/signup/SignupScreen'));
const ProfileScreen = React.lazy(() => import('./pages/profile/ProfileScreen'));
const SuccessScreen = React.lazy(() => import('./pages/success/SuccessScreen'));
const UpsellScreen = React.lazy(() => import('./pages/plans/UpsellScreen'));
const ErrorScreen = React.lazy(() => import('./pages/error/ErrorScreen'));
const PlanCheckout = React.lazy(() =>
  import('./pages/PlanCheckout/PlanCheckout')
);
const Q2 = React.lazy(() => import('./pages/questions/Q2'));
const Q3 = React.lazy(() => import('./pages/questions/Q3'));
const Q4 = React.lazy(() => import('./pages/questions/Q4'));
const Q5 = React.lazy(() => import('./pages/questions/Q5'));
const Q6 = React.lazy(() => import('./pages/questions/Q6'));
const Q7 = React.lazy(() => import('./pages/questions/Q7'));
const Q8 = React.lazy(() => import('./pages/questions/Q8'));
const Q9 = React.lazy(() => import('./pages/questions/Q9'));
const Q10 = React.lazy(() => import('./pages/questions/Q10'));
const FactOne = React.lazy(() => import('./pages/facts/FactOne'));
const Q11 = React.lazy(() => import('./pages/questions/Q11'));
const Q12 = React.lazy(() => import('./pages/questions/Q12'));
const Q13 = React.lazy(() => import('./pages/questions/Q13'));
const Q14 = React.lazy(() => import('./pages/questions/Q14'));
const FactTwo = React.lazy(() => import('./pages/facts/FactTwo'));
const Q15 = React.lazy(() => import('./pages/questions/Q15'));
const Q16 = React.lazy(() => import('./pages/questions/Q16'));
const Q17 = React.lazy(() => import('./pages/questions/Q17'));
const Q18 = React.lazy(() => import('./pages/questions/Q18'));
const Q19 = React.lazy(() => import('./pages/questions/Q19'));
const Q20 = React.lazy(() => import('./pages/questions/Q20'));
const FactThree = React.lazy(() => import('./pages/facts/FactThree'));
const Q21 = React.lazy(() => import('./pages/questions/Q21'));
const Q22 = React.lazy(() => import('./pages/questions/Q22'));
const Q23 = React.lazy(() => import('./pages/questions/Q23'));
const RouteWithAppContainer = ({
  element: Element,
  customHeight,
  isOptions,
}) => (
  <AppContainer customHeight={customHeight} isOptions={isOptions}>
    <AppProgressBar isOptions={isOptions} />
    <Element />
  </AppContainer>
);

const routes = [
  ...landingTypesList.map((type) => ({
    path: type,
    element: <Welcome />,
  })),
  {
    path: '/q2',
    element: <RouteWithAppContainer element={Q2} />,
  },
  {
    path: '/q3',
    element: <RouteWithAppContainer element={Q3} />,
  },
  {
    path: '/q4',
    element: <RouteWithAppContainer element={Q4} />,
  },
  {
    path: '/q5',
    element: <RouteWithAppContainer element={Q5} />,
  },
  {
    path: '/q6',
    element: <RouteWithAppContainer element={Q6} />,
  },
  {
    path: '/q7',
    element: <RouteWithAppContainer element={Q7} />,
  },
  {
    path: '/q8',
    element: <RouteWithAppContainer element={Q8} />,
  },
  {
    path: '/q9',
    element: <RouteWithAppContainer element={Q9} />,
  },
  {
    path: '/q10',
    element: <RouteWithAppContainer element={Q10} />,
  },
  {
    path: '/f1',
    element: <FactOne />,
  },
  {
    path: '/q11',
    element: <RouteWithAppContainer element={Q11} />,
  },
  {
    path: '/q12',
    element: <RouteWithAppContainer element={Q12} />,
  },
  {
    path: '/q13',
    element: <RouteWithAppContainer element={Q13} />,
  },
  {
    path: '/q14',
    element: <RouteWithAppContainer element={Q14} />,
  },
  {
    path: '/f2',
    element: <FactTwo />,
  },
  {
    path: '/q15',
    element: <RouteWithAppContainer element={Q15} />,
  },
  {
    path: '/q16',
    element: <RouteWithAppContainer element={Q16} />,
  },
  {
    path: '/q17',
    element: <RouteWithAppContainer element={Q17} />,
  },
  {
    path: '/q18',
    element: <RouteWithAppContainer element={Q18} />,
  },
  {
    path: '/q19',
    element: <RouteWithAppContainer element={Q19} />,
  },
  {
    path: '/q20',
    element: <RouteWithAppContainer element={Q20} />,
  },
  {
    path: '/f3',
    element: <FactThree />,
  },
  {
    path: '/q21',
    element: <RouteWithAppContainer element={Q21} />,
  },
  {
    path: '/q22',
    element: <RouteWithAppContainer element={Q22} />,
  },
  {
    path: '/q23',
    element: <RouteWithAppContainer element={Q23} />,
  },
  {
    path: '/press',
    element: <Press />,
  },
  {
    path: '/choose-target',
    element: <RouteWithAppContainer element={TargetTrack} />,
  },
  {
    path: '/choose-location',
    element: <RouteWithAppContainer element={LocationTrack} />,
  },
  {
    path: '/choose-reason',
    element: <RouteWithAppContainer element={ReasonTrack} />,
  },
  {
    path: '/choose-time',
    element: <RouteWithAppContainer element={TimeTrack} />,
  },
  {
    path: '/choose-frequency',
    element: <RouteWithAppContainer element={FrequencyTrack} />,
  },
  {
    path: '/choose-location-type',
    element: <RouteWithAppContainer element={LocationTypeTrack} />,
  },
  {
    path: '/choose-quantity',
    element: <RouteWithAppContainer element={QuantityTrack} />,
  },
  {
    path: '/choose-feature',
    element: <RouteWithAppContainer element={FeatureTrack} />,
  },
  {
    path: '/explore-solutions',
    element: <RouteWithAppContainer element={ExploreSolutions} isOptions />,
  },
  {
    path: '/real-time-location',
    element: <RouteWithAppContainer element={RealTimeLocation} isOptions />,
  },
  {
    path: '/place-alerts',
    element: <RouteWithAppContainer element={PlaceAlerts} isOptions />,
  },
  {
    path: '/ar-search',
    element: <RouteWithAppContainer element={ArSearch} isOptions />,
  },
  {
    path: '/sos-alerts',
    element: <RouteWithAppContainer element={SosAlerts} isOptions />,
  },
  {
    path: '/platform-support',
    element: <RouteWithAppContainer element={PlatformSupport} isOptions />,
  },
  {
    path: '/summary',
    element: <Summary />,
  },
  {
    path: '/signup',
    element: <RouteWithAppContainer element={SignupScreen} />,
  },
  {
    path: '/plan-ready',
    element: <ReadyPlan />,
  },
  {
    path: '/creating-profile',
    element: <RouteWithAppContainer element={ProfileScreen} />,
  },
  {
    path: '/checkout',
    element: (
      <RouteWithAppContainer element={PlanCheckout} customHeight={'auto'} />
    ),
  },
  {
    path: '/checkout/success',
    element: <SuccessScreen />,
  },
  {
    path: '/checkout/error',
    element: <RouteWithAppContainer element={ErrorScreen} />,
  },
  {
    path: '/checkout/upsell',
    element: <RouteWithAppContainer element={UpsellScreen} />,
  },
  {
    path: '*',
    element: <Navigate to="/" />,
  },
];

const RouterPath = ({ dispatch }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const setPath = useCallback(
    (path) => {
      dispatch(setActivePage(path.replace('/', '')));
    },
    [dispatch]
  );

  useEffect(() => {
    setPath(location.pathname);
    window.scrollTo(0, 0);
  }, [location.pathname, dispatch, setPath]);

  useEffect(() => {
    const handleBackButton = (event) => {
      event.preventDefault();
      navigate(location.pathname);
    };
    const {
      location: { pathname, search },
    } = window;
    const path = `${pathname}${
      landingTypesList.includes(pathname) ? search : ''
    }`;

    window.history.pushState(null, '', path);
    window.addEventListener('popstate', handleBackButton);

    return () => {
      window.removeEventListener('popstate', handleBackButton);
    };
  }, [navigate, location.pathname]);

  const wrappedRoutes = routes.map((route) => {
    if (route.wrapInAppContainer) {
      return {
        ...route,
        element: <RouteWithAppContainer element={route.element} />,
      };
    }
    return route;
  });
  return useRoutes(wrappedRoutes);
};

export default RouterPath;
