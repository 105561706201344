import styled, { useTheme } from 'styled-components';

const SVGWrapper = styled.div`
  position: relative;
`;

const SVGCircles = styled.svg`
  transform: rotate(-90deg);
`;

const SVGLabel = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: ${(props) => props.theme.fontWeights.medium};
  font-size: ${(props) => props.theme.fontSizes.font32};
  line-height: ${(props) => props.theme.lineHeights.lineHeight38};
  text-align: center;
  ${({ $customLabelStyles }) => $customLabelStyles};
`;

const AppCircularProgress = (props) => {
  const theme = useTheme();
  let {
    size = 180,
    progress = 0,
    trackWidth = 10,
    trackColor = `${theme.colors.colorInactiveTrack}`,
    indicatorWidth = 10,
    indicatorColor = `${theme.colors.colorPrimary}`,
    indicatorCap = `round`,
    labelColor = `${theme.colors.colorText}`,
    spinnerMode = false,
    spinnerSpeed = 1,
    customLabelStyles,
  } = props;

  const center = size / 2,
    radius =
      center - (trackWidth > indicatorWidth ? trackWidth : indicatorWidth),
    dashArray = 2 * Math.PI * radius,
    dashOffset = dashArray * ((100 - progress) / 100);

  return (
    <>
      <SVGWrapper style={{ width: size, height: size }}>
        <SVGCircles style={{ width: size, height: size }}>
          <circle
            cx={center}
            cy={center}
            fill="transparent"
            r={radius}
            stroke={trackColor}
            strokeWidth={trackWidth}
          />
          <circle
            style={{ animationDuration: spinnerSpeed * 1000 }}
            cx={center}
            cy={center}
            fill="transparent"
            r={radius}
            stroke={indicatorColor}
            strokeWidth={indicatorWidth}
            strokeDasharray={dashArray}
            strokeDashoffset={dashOffset}
            strokeLinecap={indicatorCap}
          />
        </SVGCircles>

        {
          <SVGLabel
            style={{ color: labelColor }}
            $customLabelStyles={customLabelStyles}
          >
            {!spinnerMode && (
              <span>{`${progress > 100 ? 100 : progress}%`}</span>
            )}
          </SVGLabel>
        }
      </SVGWrapper>
    </>
  );
};

export default AppCircularProgress;
