import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectEventsData,
  setAnalyticsData,
  setEventData,
  setEventDataList,
} from '../../store/events';
import { selectLoad, selectWebp } from '../../store/ui';
import { useLocation, useNavigate } from 'react-router-dom';
import useImagesSrc from '../../hooks/useImagesScr';
import { useLandingType, queryParser } from 'onboarding-initialization';
import landingTypesList from '../../utils/landingTypes';
import { setLandingType } from '../../store/plans';
import {
  isAndroid,
  isDesktop,
  isIOS,
  osName,
  osVersion,
} from 'react-device-detect';
import { setAmplitudeUserProperties } from '../../analytics/amplitude';
import { EVENTS } from '../../analytics/events';
import { sendAnalyticsEvents } from '../../analytics/common';
import { useAnalyticsData } from '../../utils/checkoutHelper';
import {
  AppButton,
  AppContainer,
  AppDescription,
  AppHeaderLogo,
  AppHeadline,
} from '../../components';
import styled, { css } from 'styled-components';
import useWindowSize from '../../hooks/useWindowSize';
import { usePreloadImages } from '../../hooks/usePreloadImages';
import { setLocation, setRole } from '../../store/info';
import SignupScreen from '../signup/SignupScreen';
import Q1 from '../questions/Q1';
import { useSendEvents } from '../../hooks/useSendEvents';
import {
  REACT_APP_GOOGLE_ADS_ID,
  GOOGLE_ADS_OB_START_ID,
} from '../../analytics/GA';
import ReactGA from 'react-ga4';

const isArabic = navigator.language.startsWith('ar');

const rightArrow = (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <g clipPath="url(#clip0_530_11727)">
      <path
        d="M12 21.25C17.1086 21.25 21.25 17.1086 21.25 12C21.25 6.89137 17.1086 2.75 12 2.75C6.89137 2.75 2.75 6.89137 2.75 12C2.75 17.1086 6.89137 21.25 12 21.25Z"
        fill="white"
      />
      <path
        d="M11 9.59L13 12L11 14.41"
        stroke="#00A08B"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_530_11727">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const StyledWelcomeImage = styled.div`
  background-image: ${({ imgPath }) => imgPath && `url('${imgPath}')`};
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  max-width: 375px;
  max-height: 345px;
  min-height: 150px;
  height: 100%;
  width: 100%;
  margin-top: 24px;
  ${({ $isFull2 }) =>
    $isFull2 &&
    css`
       {
        margin-top: -16px;
      }
    `};
  @media screen and (max-width: 450px) {
    width: 100vw;
  }
  @media screen and (max-height: 700px) {
    margin-top: 5px;
    ${({ $isFull2 }) =>
      $isFull2 &&
      css`
         {
          margin-top: -16px;
        }
      `};
  }
  @media screen and (max-height: 600px) {
    height: 300px;
  }
  @media screen and (max-height: 530px) {
    height: 250px;
  }
`;

const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  ${({ $isIntro, $isFull2 }) =>
    ($isIntro || $isFull2) &&
    css`
      justify-content: flex-start;
      overflow: hidden;
    `};

  @media screen and (max-width: 450px) {
    padding-bottom: ${({ $isFull2 }) => ($isFull2 ? '80px' : '175px')};
  }
  ${({ $isIntro2 }) =>
    $isIntro2 &&
    css`
      margin: 0 auto;
      height: auto;
      @media screen and (max-width: 450px) {
        padding-bottom: 0;
      }
    `}
`;

const MainWrapperFullprice = styled(MainWrapper)`
  @media screen and (max-height: 600px) {
    height: 100vh;
  }
`;

const WelcomeDisclaimer = styled.p`
  color: ${({ theme }) => theme.colors.colorWelcomeDisclaimerText};
  text-align: center;
  font-weight: ${(props) => props.theme.fontWeights.extraLight};
  font-size: ${(props) => props.theme.fontSizes.font12};
  letter-spacing: -0.288px;
  white-space: pre-line;
  max-width: 343px;
  > span {
    color: ${({ theme }) => theme.colors.colorPrimary};
    font-weight: ${(props) => props.theme.fontWeights.light};
    text-decoration-line: underline;
    cursor: pointer;
  }
  ~ #review {
    padding-top: 10px;
  }
  &#intro {
    margin: 30px 0 10px;
    color: ${({ theme }) => theme.colors.colorDomain};
    > span {
      color: ${({ theme }) => theme.colors.colorDomain};
      font-weight: ${(props) => props.theme.fontWeights.extraLight};
      text-decoration-line: underline;
    }
  }
`;

const ListContainer = styled.div`
  margin: 25px 0 35px;
  display: grid;
  grid-template-columns: repeat(2, 155px);
  grid-template-rows: repeat(2, 188px);
  gap: 16px;
  @media screen and (max-height: 600px) {
    margin: 15px 0;
  }
`;

const ListItem = styled.div`
  border-radius: 19px;
  background: #e9f0f4;
  position: relative;
  > img {
    max-width: 100%;
    object-fit: contain;
  }
  &:hover {
    cursor: pointer;
    opacity: 0.9;
  }
`;

const ListButton = styled.div`
  background-color: ${({ theme }) => theme.colors.colorPrimary};
  color: ${({ theme }) => theme.colors.colorWhite};
  font-weight: ${(props) => props.theme.fontWeights.bold};
  font-size: ${(props) => props.theme.fontSizes.font12};
  position: absolute;
  z-index: 100;
  bottom: 0;
  width: 100%;
  min-height: 40px;
  max-height: 40px;
  display: flex;
  align-items: center;
  flex-direction: ${isArabic ? 'row-reverse' : 'row'};
  justify-content: space-between;
  border-bottom-left-radius: 19px;
  border-bottom-right-radius: 19px;
  padding: 0 12px;
  > svg {
    min-height: 24px;
    min-width: 24px;
    rotate: ${isArabic && '180deg'};
  }
`;

const WelcomeContainer = styled.div`
  > img {
    max-width: 343px;
    object-fit: contain;
    @media screen and (max-width: 450px) {
      max-width: 100vw;
    }
  }
`;

const TrustedWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const TrustedContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LeftBranchIcon = styled.div`
  background: url(${'../assets/welcome/branch-left.png'}) no-repeat;
  background-size: contain;
  width: 23px;
  height: 48px;
  margin-right: 10px;
`;

const RightBranchIcon = styled(LeftBranchIcon)`
  background: url(${'../assets/welcome/branch-right.png'}) no-repeat;
  background-size: contain;
  margin-left: 10px;
  margin-right: 0;
`;

const QuoteTitle = styled.div`
  color: rgba(0, 160, 139, 1);
  font-size: 18px;
  font-weight: 700;
  line-height: 18px;
  text-align: center;
`;

const QuoteDescription = styled.div`
  color: rgba(132, 132, 132, 1);
  font-size: 12px;
  font-weight: 600;
  line-height: 14px;
  margin-top: 4px;
  text-align: center;
  max-width: 250px;
`;

const WelcomeTitle = styled.h1`
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  color: #232323;
  white-space: pre-line;
  text-align: center;
`;

export const onLinkClick = ({ target: { id } }, paywall) => {
  const links = {
    terms: 'https://geozilla.com/terms-of-use/',
    privacy: 'https://geozilla.com/privacy-policy/',
  };
  sendAnalyticsEvents(
    paywall === 'intro_offer2'
      ? 'start_where_partner__click'
      : paywall === 'full2'
      ? 'start__click'
      : 'link__click',
    {
      link__redirect: id,
    }
  );
  if (id && links[id]) window.open(links[id]);
};

const Welcome = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [eventParams, setEventParams] = useState(null);
  const eventsData = useSelector(selectEventsData);
  const load = useSelector(selectLoad);
  const navigate = useNavigate();
  const location = useLocation();
  const analyticsParams = useAnalyticsData();
  const { imagesSources } = useImagesSrc();
  const windowSize = useWindowSize();
  const { innerHeight } = windowSize;
  const isWebp = useSelector(selectWebp);
  const landingParam = location.pathname.replace(/\//g, '');
  const { landingType, paywallType } = useLandingType(
    landingParam,
    landingTypesList
  );
  const isFullPrice1 = paywallType === 'fullprice_1';
  const isFullPrice2 = paywallType === 'fullprice_2';
  const isRunwayerFlow = ['air'].includes(paywallType);
  const isIntroOffer = paywallType === 'intro_offer';
  const isIntroOffer2 = paywallType === 'intro_offer2';
  const isFull2 = paywallType === 'full2';
  const sendEvents = useSendEvents(isFull2 && { extraPathname: 'start' });

  const targetOptions = useMemo(
    () => [
      {
        id: 0,
        imgPath: '../assets/welcome/partner.png',
        name: t('partner'),
        value: 'Partner',
      },
      {
        id: 1,
        imgPath: '../assets/welcome/kid.png',
        name: t('kid'),
        value: 'Kid',
      },
      {
        id: 2,
        imgPath: '../assets/welcome/grandparent.png',
        name: t('grandparent'),
        value: 'Grandparent',
      },
      {
        id: 3,
        imgPath: '../assets/welcome/other1.png',
        name: t('other'),
        value: 'Other',
      },
    ],
    [t]
  );

  const locationsOptions = useMemo(
    () => [
      {
        id: 0,
        imgPath: '../assets/welcome/workhome.png',
        name: t('work_home'),
        value: 'WorkHome',
      },
      {
        id: 1,
        imgPath: '../assets/welcome/bar_restaurants.png',
        name: t('bar_restaurants'),
        value: 'BarRestaurants',
      },
      {
        id: 2,
        imgPath: '../assets/welcome/unknown_location.png',
        name: t('unknown_locations'),
        value: 'UnknownLocation',
      },
      {
        id: 3,
        imgPath: '../assets/welcome/other2.png',
        name: t('other'),
        value: 'Other',
      },
    ],
    [t]
  );

  usePreloadImages(imagesSources['press']);
  usePreloadImages('../assets/spinner-image.gif');

  useEffect(() => {
    if (eventParams || !paywallType) return;
    const { fbclid, gclid, ttclid, ...utms } = queryParser(location.search);
    const {
      utm_source,
      utm_medium,
      utm_campaign,
      utm_content,
      Adset_name,
      Ad_name,
      CampaignID,
      AdSetID,
      AdID,
    } = utms;
    const facebookUtm = fbclid ? 'facebook' : null;
    const appDomain = `${window.location.origin}${
      landingParam.length ? '/' + landingParam : ''
    }`;
    const utmSource = utms['utm_source'] || facebookUtm || 'organic';
    utms['utm_source'] = utmSource;

    dispatch(setLandingType(paywallType));

    const localEventsData = {
      landing_type: `quiz_${landingType}`,
      app_domain: appDomain,
      app_name: 'Geozilla',
      fbclid,
      gclid,
      ttclid,
      utm: utms,
      utm_source: utmSource,
      os: `${osVersion} ${osName}`,
    };

    const userProperties = {
      AppDomain: appDomain,
      OSDevice: `${
        isDesktop ? 'desktop' : isIOS ? 'iOS' : isAndroid ? 'Android' : osName
      }`,
      utm_source,
      utm_medium,
      utm_campaign,
      utm_content,
      Adset_name,
      Ad_name,
      CampaignID,
      AdSetID,
      AdID,
    };

    dispatch(setEventDataList(localEventsData));
    setAmplitudeUserProperties(userProperties);
    setEventParams(localEventsData);
  }, [dispatch, eventParams, location, landingParam, landingType, paywallType]);

  useEffect(() => {
    if (!eventParams) return;
    sendAnalyticsEvents(EVENTS.onboardingStarted, eventParams);
    if (isFull2) {
      sendEvents('start__screen__load', {
        screen__load: '/start',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventParams, isFull2]);

  useEffect(() => {
    ReactGA.event('conversion', {
      send_to: `${REACT_APP_GOOGLE_ADS_ID}/${GOOGLE_ADS_OB_START_ID}`,
    });
  }, []);

  useEffect(() => {
    dispatch(setAnalyticsData(analyticsParams));
  }, [analyticsParams, dispatch]);

  const onContinueClick = () => {
    sendAnalyticsEvents(EVENTS.onboardingStartedSubmitted, eventsData);
    isFull2 &&
      sendEvents('start__click', {
        button__continue: 'continue',
      });
    navigate('/press');
  };

  const onOptionClick = (option) => {
    const eventId = isFullPrice1 ? 'Role' : 'Locations';
    const eventType = isFullPrice1
      ? EVENTS.onboardingRoleSubmitted
      : EVENTS.onboardingLocationsSubmitted;

    dispatch(setEventData({ id: eventId, data: option?.value }));

    if (isFullPrice1) {
      dispatch(setRole(option?.name));
    } else {
      dispatch(setLocation(option?.name));
    }

    sendAnalyticsEvents(eventType, {
      ...eventsData,
      [eventId]: option?.value,
    });

    navigate('/press');
  };

  const renderListItem = (item, index) => (
    <ListItem key={index} onClick={() => onOptionClick(item)}>
      <img src={item.imgPath} alt={item.name} />
      <ListButton>
        {item.name} {rightArrow}
      </ListButton>
    </ListItem>
  );

  if (isRunwayerFlow) {
    return (
      <AppContainer customStyles={'padding-top: 20px;'}>
        <SignupScreen />
      </AppContainer>
    );
  }

  if (!paywallType) {
    return null;
  }

  if (load) {
    if (isIntroOffer || isIntroOffer2) {
      return (
        <MainWrapper
          $isIntro={isIntroOffer || isIntroOffer2}
          $isIntro2={isIntroOffer2}
        >
          {isIntroOffer2 && (
            <WelcomeContainer>
              <AppHeaderLogo />
              <TrustedWrapper>
                <LeftBranchIcon />
                <TrustedContent>
                  <QuoteTitle>{t('25_million_users')}</QuoteTitle>
                  <QuoteDescription>{t('minute_quiz')}</QuoteDescription>
                </TrustedContent>
                <RightBranchIcon />
              </TrustedWrapper>
              <img
                src="../assets/welcome/welcome_img.png"
                alt="Geozilla location history"
              />
            </WelcomeContainer>
          )}
          <Q1 isIntroOffer2={isIntroOffer2} />
          {isIntroOffer2 && (
            <WelcomeDisclaimer
              id="intro"
              dangerouslySetInnerHTML={{ __html: t('welcome_disclaimer_v3') }}
              onClick={(e) => onLinkClick(e, paywallType)}
            />
          )}
        </MainWrapper>
      );
    }
    if (isFull2) {
      return (
        <MainWrapper $isFull2={isFull2}>
          <AppHeaderLogo />
          <StyledWelcomeImage
            $isFull2={isFull2}
            imgPath={
              isWebp
                ? '../assets/welcome/welcome_full.webp'
                : '../assets/welcome/welcome_full.png'
            }
          />
          <WelcomeTitle>{t('welcome_sub')}</WelcomeTitle>
          <AppButton
            onClick={onContinueClick}
            bottomPosition={'16px'}
            customBeforeBg={'none'}
            customStyles={'height: 56px'}
            withHint
            withAddress={false}
            paywallType={paywallType}
          />
        </MainWrapper>
      );
    }
    if (isFullPrice1 || isFullPrice2) {
      return (
        <MainWrapperFullprice>
          <AppHeadline
            customStyles={
              'text-align: center; margin-bottom: 10px; padding: 0 10px'
            }
          >
            {t('welcome')}
          </AppHeadline>
          <AppDescription customStyles={`text-align: center;`}>
            {isFullPrice1 ? t('question1_title') : t('question2_other')}
          </AppDescription>
          <ListContainer>
            {isFullPrice1
              ? targetOptions.map(renderListItem)
              : locationsOptions.map(renderListItem)}
          </ListContainer>
          <WelcomeDisclaimer
            dangerouslySetInnerHTML={{ __html: t('welcome_disclaimer') }}
            onClick={(e) => onLinkClick(e, paywallType)}
          />
          <WelcomeDisclaimer id="review">
            {t('please_review')}
          </WelcomeDisclaimer>
        </MainWrapperFullprice>
      );
    }
    return (
      <MainWrapper>
        <AppHeadline
          customStyles={
            'text-align: center; margin-bottom: 0px; max-width: 180px'
          }
        >
          {t('welcome')}
        </AppHeadline>
        <StyledWelcomeImage
          imgPath={
            isWebp
              ? '../assets/options/Welcome.webp'
              : '../assets/options/Welcome.png'
          }
        />
        <AppDescription
          customStyles={`font-size: 20px; line-height: 24px; margin-top: ${
            innerHeight <= 700 ? '-20px' : '10px'
          }; text-align: center; padding: 0px 10px`}
        >
          {t('welcome_sub')}
        </AppDescription>
        <AppButton
          customText={'lets_go'}
          onClick={onContinueClick}
          bottomPosition={'16px'}
          customBeforeBg={'none'}
          withHint
          paywallType={paywallType}
        />
      </MainWrapper>
    );
  }
  return <></>;
};

export default Welcome;
